import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./Pages/HomeMain/Home";
import SinglePage from "./Pages/SinglePage/SinglePage";
import ContactMainCompo from "./Pages/ContactMain/ContactMainCom";
import { useEffect, useState } from "react";
import { Context } from "./Context/ContextStates";
import AddCompanyMain from "./Pages/AddCompnay/AddComapnyMain";
import { GetCategories } from "./Api/Api";
import FavMain from "./Pages/Favourites/FavMain";
import Login from "./Pages/AuthFlow/Login";
import Toast from "./Components/ToastifyCompo";
import Register from "./Pages/AuthFlow/Register";
import MasonryLayout from "./Components/Masonry";

function App() {
  const [search, setSearch] = useState("");
  const [category,setCategory] = useState([])
  
  useEffect(() => {
    GetCategories().then((e) => {
      setCategory(e)
    })  
  },[])
  return (
    <div className="App">
      <Context.Provider value={{ search, setSearch,category,setCategory }}>
        <Router>
          <Routes>
            <Route path="/mas" element={<MasonryLayout/>}/>
            <Route path="/" element={<HomePage />} />
            <Route path="/details" element={<SinglePage />} />
            <Route path="/contact" element={<ContactMainCompo />} />
            <Route path="/favourite" element={<FavMain />} />
            <Route path="/profiel-toevoegen" element={<AddCompanyMain />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />

          </Routes>
        </Router>
      </Context.Provider>
      <Toast />

    </div>
  );
}

export default App;
