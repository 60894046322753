import * as React from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';

const ButtonStyle = {
  fontWeight: 600,
  fontSize: '15px',
  color: '#13492D',
  borderRadius: 35,
  padding: '10px',
  marginTop: '2%',
  backgroundColor: '#F7B324',
  textDecoration: 'none',
  textTransform: 'none',
  height:'45px'
};

const disabledButtonStyle = {
  ...ButtonStyle,
  cursor: 'not-allowed',
};

export default function ButtonCompo({ width, title, disabled,onClick,padding,loading }) {
  const buttonStyles = disabled ? disabledButtonStyle : ButtonStyle;

  return (
    <Button
      size='small'
      type='submit'
      style={{ ...buttonStyles, width: width,padding:padding }}
      disabled={disabled}
      onClick={onClick}
      loading={loading}
    >
      {loading ? (
        <CircularProgress size={20} color="inherit" />
      ) : (
        title
      )}
    </Button>
  );
}
