import axios from "axios";
// const URL = 'http://localhost:5000/'
const URL = 'https://digitalbackend-production-bfa4.up.railway.app/'


export async function GetList() {
    try {
        const response = await axios.get(URL + "result")
        return response.data
    } catch (error) {
        return error.response.data
    }

}

export async function GetCategories() {
    try {
        const response = await axios.get(URL + "categories/")
        return response.data
    } catch (error) {
        return error.response.data
    }

}
// AddCompany
export async function AddCompanyApi(value, category) {
    console.log(value,category)
    try {
        const response = await axios.post(URL + `company/add-company`, {
            Bedrijfsnaam: value.bedrijfsnaam,
            Facebookadres: value.url,
            contactperson: value.contactperson,
            email: value.email,
            categories: category
        });
        return response.data;
    } catch (error) {
        return error.response.data

    }
}
