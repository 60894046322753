import styled from "styled-components";

export const AuthMain = styled.div`
background-color: #FFFFFF;
flex: 1;
  display: flex;
    /* justify-content: center;
    align-items: center;
    min-height: 100vh; */


`
export const AuthMain1 = styled.div`
background-color: #FFFFFF;
flex: 1;
  display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;


`
export const PositionBetween = styled.div`
display:flex;
flex-direction:row;
justify-content:space-between;
@media (max-width: 950px) {
    flex-direction:column;
  }
`
export const FormContainer = styled.div`
width: 35%;
margin-left: 15%;
@media (max-width: 950px) {
  margin-left: 0%;
  margin: auto !important;
  width: 90%;
  
  }

`
export const RowOnly = styled.div`
display: flex;
flex-direction: row;
`
export const ColDirection = styled.div`
display: flex;
flex-direction: column;
`
export const PositionBetweenOnly = styled.div`
display:flex;
flex-direction:row;
justify-content:space-between;
`
export const HeadingAuth = styled.p`
font-size: 25px;
color: #0A2252;
font-weight: 600;
margin-bottom: 3px;
margin-top: 1px;
@media (max-width: 450px) {
    font-size: 25px;
    text-align: center;
  }
`
export const LightTextAuth = styled.p`
font-size: 13px;
font-weight: 500;
color: #9FA4AA;

/* display: inline; */
@media (max-width: 450px) {
    font-size: 13px;
    text-align: center;
    
  }
`
export const EmailTextAuth = styled.p`
font-size: 12px;
font-weight: 500;
color: #0A2252;
display: inline;

`
export const LinkTextAuth = styled.p`

font-style: normal;
font-weight: 500;
font-size: 14px;
color: #9FA4AA;
display: inline;
`;
export const LinkOnly = styled.p`
font-size: 13px;
font-weight: 600;
color: #5076FF;
cursor: pointer;
align-self: center;
`
export const Label = styled.p`
font-size: 12px;
color: #0A2252;
font-weight: 600;
margin-bottom: 6px;
margin-top: 5px;
margin-left: 10px;
`
export const ErrorText = styled.p`
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 12px;
text-align: left;

color: #FF5353;

margin-left: 1%;
margin-top: 6px;
margin-bottom: 0px;

`;

export const BackAccountType = styled.div`
border:1px solid #EAEAEF;
border-radius: 8px;
display: flex;
align-items: center;
width: 36.5vh;
padding: 12px;
cursor: pointer;
@media (max-width: 450px) {
    margin-bottom: 2%;
    width: 90%;

    
  }
`
export const ActiveBackAccountType = styled.div`
border:1.4px solid #5076FF;
border-radius: 8px;
display: flex;
align-items: center;
width: 36.5vh;
padding: 12px;
cursor: pointer;
@media (max-width: 450px) {
    margin-bottom: 2%;
    width: 90%;

    
  }

`
export const AccountTitle = styled.p`
font-size: 15px;
font-weight: 500;
color: #0A2252;
margin-bottom: 0px;
margin-left: 10px;

`
export const AccountSubTitle = styled.p`
font-size: 12px;
font-weight: 500;
color: #9FA4AA;
margin-left: 10px;
margin-top: 5px;

`
export const BottomText = styled.p`
font-size: 12px;
font-weight: 500;
color: #1C295D;
position: absolute;
bottom: 10px;

`
export const BottomTextReg = styled.p`
font-size: 12px;
font-weight: 500;
color: #1C295D;
margin-top: 2%;
@media (max-width: 450px) {
  margin-top: 10%;

    
  }

`