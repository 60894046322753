import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import InputCompo from "../../Components/InputCompo";
import PasswordInput from "../../Components/PasswordInput";
import LinkText from "../../Components/LinkText";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  AuthMain,
  ColDirection,
  ErrorText,
  FormContainer,
  HeadingAuth,
  Label,
  LightTextAuth,
} from "./AuthStyles";
import ButtonCompo from "../../Components/ButtonCompo";
import useMediaQuery from "../../Hooks/MediaQuery";
import AuthImg from "../../Assets/Images/contact_image_2.png";
import Logo from "../../Assets/Images/Alles-in-Tubbergen_logo.png"

const Login = () => {
  const isMobile = useMediaQuery("(min-width: 950px)");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Enter a valid email")
        .required("Email is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: (values) => {
      setLoading(true);
      // handle form submission logic
    },
  });

  return (
    <AuthMain
      style={{
        display: "flex",
        alignItems: "center",
        height: "100vh",
        position: "relative",
      }}
    >
            <FormContainer style={{marginTop:'2%'}}>
            <ColDirection>
        <img src={Logo} style={{width:'250px',height:'90px',objectFit:'contain',margin:!isMobile && 'auto'}}/>
          <div>
            <HeadingAuth>Hi, Welcome Back</HeadingAuth>
            <LightTextAuth>Sign in now to unlock exclusive features!</LightTextAuth>
          </div>
          <form onSubmit={formik.handleSubmit} autoComplete="off" noValidate>
            <ColDirection>
              <Label>Email Address</Label>
              <InputCompo
                id="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                placeholder="John@gmail.com"
                width="100%"
                autoComplete="no"              />
              {formik.touched.email && formik.errors.email ? (
                <ErrorText>{formik.errors.email}</ErrorText>
              ) : null}
            </ColDirection>
            <ColDirection>
              <Label>Password</Label>
              <PasswordInput
                id="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                placeholder=""
                width="100%"
                autoComplete="no"
                              />
              {formik.touched.password && formik.errors.password ? (
                <ErrorText>{formik.errors.password}</ErrorText>
              ) : null}
            </ColDirection>
            <div
              style={{
                margin: "auto",
                textAlign: "center",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "6%",
                marginTop: "3%" 
              }}
            >
              <ButtonCompo
                title="Sign In"
                width="100%"
                loading={loading}
              />
            </div>
            <div style={{ textAlign: "center", marginTop: "3%" }}>
              <LinkText
                title="Don't have an account?"
                title1="Create Account"
                onClick={() => navigate("/register")}
              />
            </div>
          </form>
        </ColDirection>
      </FormContainer>
      {isMobile && (
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
            position: "absolute",
            right: "0px",
            top: "0px",
          }}
        >
          <img
            src={AuthImg}
            style={{
              maxWidth: "70%",
              height: "100vh",
              maxHeight: "100vh",
              objectFit: "cover",
            }}
            alt="Auth illustration"
          />
        </div>
      )}
    </AuthMain>
  );
};

export default Login;
