// import React, { useContext, useEffect, useState } from "react";
// import { ArrowIc, BackLink, HeaderContainer, HeaderSubContainer, HeaderTitle, HeaderTitleActive, Logoimgmain, RightTitleHeader, TextLink, Underline } from "./HeaderStyles";
// import Logo from "../../Assets/Images/Alles-in-Tubbergen_logo.png"
// import Button from '@mui/material/Button';
// import { useNavigate } from "react-router";
// import useMediaQuery from "../../Hooks/MediaQuery";
// import UserMenu from "../UserMenu";
// import { useLocation } from "react-router-dom";
// import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
// import StarIcon from '../../Assets/Images/star.png'


// const Header = () => {
//     const isMobile = useMediaQuery('(min-width: 950px)');
//     const navigate = useNavigate();
//     const location = useLocation();
//     const [isHovered, setIsHovered] = useState(false);



//     return (
//         <HeaderContainer>
//             <HeaderSubContainer>
//                 <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%', marginBottom: '0%' }}>
//                     <Logoimgmain src={Logo} onClick={() => navigate('/')} />
//                     {isMobile ?
//                         <div style={{ display: 'flex', flexDirection: 'row', }}>
//                             {location.pathname !== '/' && (
//                                 <BackLink onClick={() => navigate('/')}>
//                                     <ArrowIc>←</ArrowIc>
//                                     <TextLink>terug naar het overzicht</TextLink>
//                                     <Underline></Underline>
//                                 </BackLink>
//                             )}
//                             <div style={{ display: 'flex', flexDirection: 'row', marginRight: '25px', cursor: 'pointer' }} onClick={() => navigate('/add-company')} >
//                                 <RightTitleHeader isActive={location.pathname === "/add-company"}>Voeg jouw bedrijf toe</RightTitleHeader>
//                             </div>
//                             {/* <div style={{ display: 'flex', flexDirection: 'row', marginRight: '25px', cursor: 'pointer' }} onClick={() => navigate('/favourite')}>
//                                 <img src={StarIcon} style={{ width: '20px', height: '20px', alignSelf: 'center' }} />
//                                 <RightTitleHeader isActive={location.pathname === "/favourite"}>Favorieten</RightTitleHeader>
//                             </div> */}
//                             <div style={{ display: 'flex', flexDirection: 'row', marginRight: '0px', cursor: 'pointer' }} onClick={() => navigate('/contact')}>
//                                 <RightTitleHeader isActive={location.pathname === "/contact"}>Contact</RightTitleHeader>
//                             </div>
//                         </div>
//                         :
//                         <div style={{ display: 'flex', flexDirection: 'row', }}>
//                             <UserMenu />
//                         </div>
//                     }




//                 </div>
//             </HeaderSubContainer>
//         </HeaderContainer >
//     );
// };

// export default Header;
import React, { useState } from "react";
import { Grid, Button, Container } from "@mui/material";
import { useNavigate } from "react-router";
import useMediaQuery from "../../Hooks/MediaQuery";
import UserMenu from "../UserMenu";
import { useLocation } from "react-router-dom";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Logo1 from "../../Assets/Images/wlogo-removebg-preview.png";
import Logo from "../../Assets/Images/yellowlogo-removebg-preview.png";

import StarIcon from '../../Assets/Images/star.png';
import { ArrowIc, BackLink, HeaderContainer, HeaderSubContainer, HeaderTitle, HeaderTitleActive, Logoimgmain, Logoimgmain1, RightTitleHeader, TextLink, Underline } from "./HeaderStyles";

const Header = () => {
    const isMobile = useMediaQuery('(min-width: 950px)');
    const navigate = useNavigate();
    const location = useLocation();
    const [isHovered, setIsHovered] = useState(false);

    return (
        <HeaderContainer>
            <Container
                sx={{
                    paddingLeft: { md: "0px" },
                    paddingRight: { md: "0px" },
                    maxWidth: { xs: "95%", sm: "90%", md: "90%", lg: "90%", xl: "90%" }, // Adjust width based on screen sizes
                    padding: '0px',    // No padding inside the container
                    margin: '0 auto',  // Center the container horizontally
                }}
            >
                <Grid container alignItems="center" justifyContent="space-between" width="100%">
                    <Grid item>
                        {location.pathname == '/' ?
                            <Logoimgmain src={Logo} onClick={() => navigate('/')} />
                            :
                            <Logoimgmain1 src={Logo1} onClick={() => navigate('/')} />
                        }
                    </Grid>
                    <Grid item>
                        <Grid container alignItems="center" spacing={3}>
                            {isMobile ? (
                                <>
                                    {location.pathname !== '/' && (
                                        <Grid item>
                                            <BackLink onClick={() => navigate('/')}>
                                                <ArrowIc>←</ArrowIc>
                                                <TextLink>terug naar het overzicht</TextLink>
                                                <Underline />
                                            </BackLink>
                                        </Grid>
                                    )}
                                    <Grid item>
                                        <div style={{ cursor: 'pointer' }} onClick={() => navigate('/profiel-toevoegen')}>
                                            <RightTitleHeader
                                                isActive={location.pathname === "/profiel-toevoegen"}
                                                style={{ color: location.pathname == "/" && '#fff' }}
                                            >
                                                Voeg jouw bedrijf toe
                                            </RightTitleHeader>
                                        </div>
                                    </Grid>
                                    {/* <Grid item>
                                        <div style={{ cursor: 'pointer' }} onClick={() => navigate('/favourite')}>
                                            <img src={StarIcon} style={{ width: '20px', height: '20px', alignSelf: 'center' }} />
                                            <RightTitleHeader isActive={location.pathname === "/favourite"}>Favorieten</RightTitleHeader>
                                        </div>
                                    </Grid> */}
                                    <Grid item>
                                        <div style={{ cursor: 'pointer' }} onClick={() => navigate('/contact')}>
                                            <RightTitleHeader
                                                isActive={location.pathname === "/contact"}
                                                style={{ color: location.pathname == "/" && '#fff' }}
                                            >
                                                Contact
                                            </RightTitleHeader>
                                        </div>
                                    </Grid>
                                </>
                            ) : (
                                <Grid item>
                                    <UserMenu />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Container>
        </HeaderContainer>
    );
};

export default Header;
