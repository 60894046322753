import React from "react";
import Header from "../../Components/Header/Header";
import { BackImg, CardSingle, CardSingleCol, CardSingleDirection, Cardimg, SingleText, SingleTextGreen, SingleTextbold, SingleTextm, SubTitleSingle, TitleSingle } from "./SinglePageStyles";
import Scene from '../../Assets/Images/pexels.png'
import { useLocation } from "react-router-dom";
import { Avatar, Button } from "@mui/material";
import useMediaQuery from "../../Hooks/MediaQuery";
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';

const SinglePage = () => {
    const location = useLocation();
    const { cardData } = location.state;
    const isMobile = useMediaQuery('(min-width: 950px)');
    const navigate = useNavigate()

    return (
        <div>
            <Header />
            {isMobile ? <></> :
                <ArrowBackIosIcon style={{fontSize:'25px',marginLeft:'5%',marginTop:'2%'}} onClick={() => navigate('/')}/>
            }
            <TitleSingle>Bedrijfsprofiel</TitleSingle>
            <SubTitleSingle>{cardData.latestPost[0].user.name}</SubTitleSingle>
            <CardSingle>
                <BackImg>
                    <Avatar sx={{ width: 70, height: 70 }} src={cardData.latestPost[0].user.profilePic} alt="Avatar" />
                </BackImg>
                <CardSingleDirection>
                    <Cardimg src={cardData.latestPost[0].media[0].thumbnail} />
                    <CardSingleCol style={{ marginLeft: isMobile ? '12%' : '5%', marginTop: '5%' }}>
                        <SingleText>Borek 25, Manderveen,Natherland</SingleText>
                        <SingleText>78867687</SingleText>
                        <SingleText>info@abc.xom</SingleText>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: '#918450',
                                color: '#fff',
                                padding: '6px 6px',
                                fontSize: '0.9rem',
                                minWidth: '100px',
                                width: { xs: '80%', sm: 'auto' }, // For extra-small screens (xs), button width is 80%. For small screens (sm) and above, it defaults to 'auto' or you can set a specific value.
                                height: '45px',
                                borderRadius: '30px',
                                textDecoration: 'none',
                                textTransform: 'none',
                                marginTop: '6%',
                                fontFamily: 'SweetSansRegular',
                                fontWeight: '400',
                                '&:hover': {
                                    borderColor: '#918450',
                                    backgroundColor: '#efefef',
                                    color: '#918450',
                                },
                            }}
                        >
                            Bekijk de website
                        </Button>
                    </CardSingleCol>
                    <CardSingleCol style={{ marginLeft: isMobile ? '12%' : '5%', marginTop: '5%' }}>
                        <SingleTextbold>Openingstijden</SingleTextbold>
                        <SingleTextGreen>Nu geopend</SingleTextGreen>
                        <SingleTextm>Maandag | GESLOTEN</SingleTextm>
                        <SingleTextm>Minsdag | GESLOTEN</SingleTextm>
                        <SingleTextm>Woensdag | 16:00 - 20:00</SingleTextm>
                        <SingleTextm>Donderdag | 16:00 - 20:00</SingleTextm>
                        <SingleTextm>Vrijdag | 16:00 - 20:00</SingleTextm>
                        <SingleTextm>Zaterdag | 16:00 - 20:00</SingleTextm>
                        <SingleTextm style={{ marginBottom: '10px' }}>Zondag | 16:00 - 20:00</SingleTextm>
                    </CardSingleCol>
                </CardSingleDirection>
            </CardSingle>
        </div>
    )
}
export default SinglePage;