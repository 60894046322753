import React from "react";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import Favourites from "./Favourites";

const FavMain = () => {
    return (
        <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
            <Header />
            <div style={{ flex: 1 }}>
                <Favourites />
            </div>
            <Footer />
        </div>
    );
};

export default FavMain;
