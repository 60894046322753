import * as React from 'react';
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const PasswordInput = ({label, placeholder, onChange, value, style, id}) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return(
    <FormControl
      sx={{
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: '#EAEAEF', // Default border color
            borderRadius: '25px',
            border:'2px solid #EAEAEF'

          },
          '&:hover fieldset': {
            borderColor: '#0A2252', // Hover border color
          },
          '&.Mui-focused fieldset': {
            borderColor: '#0A2252', // Focus border color
          },
          '& input': {
            paddingLeft: (theme) => theme.spacing(2),
            fontSize: '16px',
            color: '#0A2252', // Text color
            fontFamily: 'SF Pro Display',
            fontWeight: '500',
            letterSpacing: '0.5px', // Adjusted for better readability
            borderRadius: '25px',
height:15
          },
        },
        "& .MuiInputBase-input::placeholder": {
          color: '#84818A',
          opacity: 1,
          fontSize: '14px'
        },
        width: "100%", // Ensures the input takes full width
        height: 40, // Consistent height for all inputs
        borderRadius: '25px',
      }}
      variant="outlined"
    >
      <OutlinedInput
        fullWidth
        value={value}
        autoComplete="new-password"

        onChange={onChange}
        placeholder={placeholder}
        style={style}
        id={id}
        type={showPassword ? 'text' : 'password'}
        
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label={label}
      />
    </FormControl>
  )
}

export default PasswordInput;
