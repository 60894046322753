import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import InputCompo from "../../Components/InputCompo";
import PasswordInput from "../../Components/PasswordInput";
import LinkText from "../../Components/LinkText";
import { useFormik } from 'formik'
import * as Yup from "yup";
import useMediaQuery from "../../Hooks/MediaQuery";
import { AuthMain, AuthMain1, BottomText, BottomTextReg, ColDirection, ErrorText, FormContainer, HeadingAuth, Label, LightTextAuth, LinkOnly, PositionBetween, PositionBetweenOnly } from "./AuthStyles";
import ButtonCompo from "../../Components/ButtonCompo";
import AuthImg from "../../Assets/Images/contact_image_2.png";
import { notifyError, notifySuccess } from "../../Components/ToastifyCompo";
import Logo from "../../Assets/Images/Alles-in-Tubbergen_logo.png"


const Register = () => {
    const isMobile = useMediaQuery('(min-width: 950px)');
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            fname: '',
            lname: ''
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email('Enter a valid email')
                .required('Email is required'),
            password: Yup.string()
                .min(8, 'Password should be at least 8 characters')
                .matches(
                    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]+$/,
                    'Password should contain at least one letter, one number, and one special character'
                )
                .required('Password is required'),
            fname: Yup.string().required('First Name is required'),
            lname: Yup.string().required('Last Name is required'),

        }),
        onSubmit: (values) => {
            setLoading(true)
        },
    });

    return (
        <AuthMain 
        style={{
            display: "flex",
            alignItems: "center",
            height: "100vh",
            position: "relative",
          }}
        >
            <FormContainer style={{marginTop:'2%'}}>
                <ColDirection style={{ width: isMobile ? '100%' : '100%' }} >
                <img src={Logo} style={{width:'250px',height:'90px',objectFit:'contain',margin:!isMobile && 'auto'}}/>

                    <div style={{marginTop:'0px'}}>
                        <HeadingAuth>Create an Account</HeadingAuth>
                        <LightTextAuth>Don't miss out on the benefits, sign up now!</LightTextAuth>
                    </div>
                    <form onSubmit={formik.handleSubmit} autoComplete="off" noValidate>
                        <>
                            <PositionBetween>
                                <ColDirection style={{ width: isMobile ? '48%' : '100%' }}>
                                    <Label>First Name</Label>
                                    <InputCompo id={'fname'} value={formik.values.fname} onChange={formik.handleChange} placeholder={'John'} width={'100%'} autoComplete="no"  />
                                    {formik.touched.fname && formik.errors.fname ? (
                                        <ErrorText>{formik.errors.fname}</ErrorText>
                                    ) : ""}                                    </ColDirection>
                                <ColDirection style={{ width: isMobile ? '48%' : '100%' }}>
                                    <Label>Last Name</Label>
                                    <InputCompo id={'lname'} value={formik.values.lname} onChange={formik.handleChange} placeholder={'Doe'} width={'100%'} autoComplete="no"  />
                                    {formik.touched.lname && formik.errors.lname ? (
                                        <ErrorText>{formik.errors.lname}</ErrorText>
                                    ) : ""}

                                </ColDirection>

                            </PositionBetween>

                            <ColDirection >
                                <Label>Email Address</Label>
                                <InputCompo id={'email'} value={formik.values.email} onChange={formik.handleChange} placeholder={'John@gmail.com'} width={'100%'} autoComplete="no"  />
                                {formik.touched.email && formik.errors.email ? (
                                    <ErrorText>{formik.errors.email}</ErrorText>
                                ) : ""}
                            </ColDirection>
                            <ColDirection>
                                <Label>Password</Label>
                                <PasswordInput id={'password'} value={formik.values.password} onChange={formik.handleChange} placeholder={''} width={'100%'} autoComplete="no"  />
                                {formik.touched.password && formik.errors.password ? (
                                    <ErrorText>{formik.errors.password}</ErrorText>
                                ) : ""}
                            </ColDirection>
                            <div style={{ margin: 'auto', textAlign: 'center', alignItems: 'center', justifyContent: 'center', marginBottom: '4%',marginTop:'3%' }}>
                                <ButtonCompo title={'Sign Up'} width={isMobile ? '100%' : '100%'} loading={loading}/>
                            </div>
                            <div style={{ textAlign: 'center', marginTop: '3%' }}>
                                <LinkText title={`Already have an account?`} title1={'Sign In'} to={''} onClick={() => navigate('/login')} />
                            </div>
                        </>
                    </form>
                </ColDirection>
            </FormContainer>
            {isMobile && (
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
            position: "absolute",
            right: "0px",
            top: "0px",
          }}
        >
          <img
            src={AuthImg}
            style={{
              maxWidth: "70%",
              height: "100vh",
              maxHeight: "100vh",
              objectFit: "cover",
            }}
            alt="Auth illustration"
          />
        </div>
      )}
        </AuthMain>
    )
}
export default Register;