import styled from "styled-components";

export const TitleSingle = styled.p`
color: black;
font-size: 25px;
text-align: center;
font-weight: 600;
margin-bottom: 0px;
font-family: 'SweetSansBold';
@media (max-width: 950px) {
  font-size: 20px;
  }
`
export const SubTitleSingle = styled.p`
color: #e94f37;
font-size: 50px;
text-align: center;
font-weight: 900;
margin-top: 0px;
margin-bottom: 5%;
font-family: 'MontBlack';
@media (max-width: 950px) {
  font-size: 28px;
  margin-bottom: 15% !important;
  }
`
export const CardSingle = styled.div`
background-color: #efefef;
width: 80%;
margin: auto;
position: relative;
margin-bottom: 5% !important;
@media (max-width: 950px) {
  width: 90%;
  margin-top: 5% !important;
  }
`
export const CardSingleDirection = styled.div`
display: flex;
flex-direction: row;
@media (max-width: 950px) {
  flex-direction: column;
  }
`
export const CardSingleCol = styled.div`
display: flex;
flex-direction: column;
`

export const Cardimg = styled.img`
width: 30%;
height: 50vh;
object-fit: inherit;
@media (max-width: 950px) {
  width: 100%;
height: 50vh;  }
`
export const SingleText = styled.p`
color: #ababab;
font-size: 14px;
font-weight: 400;
font-family: 'SweetSansRegular';
letter-spacing: -0.5px;
`
export const SingleTextm = styled.p`
color: #ababab;
font-size: 14px;
font-weight: 400;
margin-bottom: 0px;
margin-top: 4px;
font-family: 'SweetSansRegular';

`
export const SingleTextbold = styled.p`
color: #838383;
font-size: 16px;
font-weight: 600;
margin-bottom: 0px;
font-family: 'SweetSansRegular';

`
export const SingleTextGreen = styled.p`
color: #60bc60;
font-size: 14px;
font-weight: 500;
margin-top: 4px;
margin-bottom: 10%;
font-family: 'SweetSansRegular';

`

export const BackImg = styled.div`
  border-radius: 50%;
  width: 80px; // Fixed size to ensure a circle
  height: 80px; // Equal to width to ensure a circle
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  position: absolute;
  top: -40px; // Half of the height to ensure it's half outside the CardSingle
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden; // Ensures that any content beyond the round shape is not visible
  border: 2px solid #efefef;
`;

