import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Grid,
  Typography,
  TextField,
  Button,
  Box,
  InputLabel,
  FormControl,
  Chip,
  CircularProgress,
} from "@mui/material";
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import AddCImage from "../../Assets/Images/add-company-img.png";
import { useFormik } from 'formik'
import { AddCompanyApi, GetCategories } from "../../Api/Api";
import Swal from 'sweetalert2'
import { Context } from "../../Context/ContextStates";
import useMediaQuery from "../../Hooks/MediaQuery";
const AddCompany = () => {
  const [loading, setLoading] = useState('')
  const { category } = useContext(Context)
  const isMobile = useMediaQuery('(min-width: 950px)');
  const [isSubmitted, setIsSubmitted] = useState(false); // State for showing success message
  const [status, setStatus] = useState(false)
  const [selectedCategories, setSelectedCategories] = React.useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCategories(
      typeof value === 'string' ? value.split(',') : value
    );
  };
  const formik = useFormik({
    initialValues: {
      bedrijfsnaam: '',
      contactperson: '',
      email: '',
      url: '',
    },
    onSubmit: (values, { resetForm }) => { // Destructure resetForm
      setLoading(true);
      AddCompanyApi(values, selectedCategories).then((e) => {
        if (e.status) {
          setIsSubmitted(true); // Set to true on successful form submission
          setLoading(false);
          setStatus(true)
          resetForm();
          setSelectedCategories([]);
        } else {
          setIsSubmitted(true); // Set to true on successful form submission
          setLoading(false);
          setErrorMessage(e.message);
        }
      })
    },
  });

  return (
    <Container
      sx={{
        py: 5,
        paddingLeft: { md: "0px" },
        paddingRight: { md: "0px" },
        // width:isMobile ? "90%" : '100%',  // Ensure the container takes 90% width
        // maxWidth: { xs: "90%", sm: "90%",md:"90%", lg: "90%",xl:"90%" }, // Adjust width based on screen sizes
        maxWidth: { xs: "100%", sm: "90%", md: "90%", lg: "90%", xl: "90%" },  // Different widths for different breakpointsW
      }}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            align="center"
            sx={{ fontWeight: "900", color: "#015a3b", mb: 2, fontFamily: 'MontExtraBold', fontSize: '40px' }}
          >
            Voeg jouw bedrijf toe
          </Typography>
          <Typography
            variant="body2"
            align="center"
            color="textSecondary"
            sx={{ mb: 4, width: isMobile ? '70%' : '90%', textAlign: 'center', alignSelf: 'center', margin: 'auto', color: "#a29390" }}
            style={{ fontWeight: '300', fontFamily: 'SweetSansRegular', marginBottom: isMobile ? '' : '10%' }}

          >
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          sx={{ marginTop: { xs: "-140px", md: "0" } }}
          order={{ xs: 3, md: 1 }} // Reorder on mobile
        >
          <Box
            component="img"
            src={AddCImage}
            alt=""
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
              height: { xs: "250px", md: "440px",lg:"440px",xl:"500px" }, // Set a max height for both mobile and desktop
              paddingTop: "25px",
              paddingBottom: "50px",
              borderRadius: {
                xs: "0px 0px 4px 4px", // Apply for mobile screens
                md: "4px 0px 0px 4px", // Apply for medium and larger screens
              },
            }}
          />
        </Grid>
        <Grid
          xs={12}
          md={5}
          sx={{
            paddingLeft: { xs: 3, md: 0 },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          order={{ xs: 1, md: 2 }} // Reorder on mobile
        >
          <form onSubmit={formik.handleSubmit} >

            <Box
              sx={{
                paddingTop: { xs: "50px", md: "50px",xl:"80px" },
                paddingBottom: { xs: "100px", md: "50px",xl:"80px" },
                paddingLeft: { md: "50px" },
                p: 3.5,
                backgroundColor: "#f3f3f3",
                borderRadius: {
                  xs: "4px 4px 0px 0px",
                  md: "0px 4px 4px 0px",
                },
              }}
            >
              {/* Bedrijfsnaam Field */}
              <Grid container spacing={2} alignItems="center" sx={{ mb: 1.5 }}>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="body1"
                    color={"#015a3b"}
                    fontSize={"12px"}
                    fontWeight={"bold"}
                    fontFamily={"SweetSansBold"}
                  >
                    Bedrijfsnaam
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Bedrijfsnaam"
                    name="bedrijfsnaam"
                    required
                    value={formik.values.bedrijfsnaam}
                    onChange={formik.handleChange}
                    InputProps={{
                      sx: {
                        backgroundColor: "white",
                        height: "2.8em", // Set the height of the TextField
                        "& .MuiInputBase-input": {
                          height: "2.8em", // Set the height of the input itself
                          padding: "0 14px", // Add horizontal padding for better input spacing
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "4px", // Optional: Add border radius

                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>

              {/* Contactpersoon Field */}
              <Grid container spacing={2} alignItems="center" sx={{ mb: 1.5 }}>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="body1"
                    color={"#015a3b"}
                    fontSize={"12px"}
                    fontWeight={"bold"}
                    fontFamily={"SweetSansBold"}

                  >
                    Contactpersoon
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="Contactpersoon"
                    name="contactperson"
                    value={formik.values.contactperson}
                    onChange={formik.handleChange}
                    required
                   InputProps={{
                      sx: {
                        backgroundColor: "white",
                        height: "2.8em", // Set the height of the TextField
                        "& .MuiInputBase-input": {
                          height: "2.8em", // Set the height of the input itself
                          padding: "0 14px", // Add horizontal padding for better input spacing
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "4px", // Optional: Add border radius

                        },
                      },
                    }}
                  />
                </Grid>
              </Grid>

              {/* E-mail Field */}
              <Grid container spacing={2} alignItems="center" sx={{ mb: 1.5 }}>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="body1"
                    color={"#015a3b"}
                    fontSize={"12px"}
                    fontWeight={"bold"}
                    fontFamily={"SweetSansBold"}

                  >
                    E-mail
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="example@abc.com"
                    name="email"
                    required
                    type="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    InputProps={{
                      sx: {
                        backgroundColor: "white",
                        height: "2.8em", // Set the height of the TextField
                        "& .MuiInputBase-input": {
                          height: "2.8em", // Set the height of the input itself
                          padding: "0 14px", // Add horizontal padding for better input spacing
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "4px", // Optional: Add border radius

                        },
                      },
                    }}
                  />

                </Grid>
              </Grid>

              {/* URL Social Media Account Field */}
              <Grid container spacing={2} alignItems="center" sx={{ mb: 1.5 }}>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="body1"
                    color={"#015a3b"}
                    fontSize={"12px"}
                    fontWeight={"bold"}
                    fontFamily={"SweetSansBold"}

                  >
                    URL Social media account
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    placeholder="https://www.example.com/abc"
                    name="url"
                    value={formik.values.url}
                    onChange={formik.handleChange}
                    required
                    InputProps={{
                      sx: {
                        backgroundColor: "white",
                        height: "2.8em", // Set the height of the TextField
                        "& .MuiInputBase-input": {
                          height: "2.8em", // Set the height of the input itself
                          padding: "0 14px", // Add horizontal padding for better input spacing
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                          borderRadius: "4px", // Optional: Add border radius

                        },
                      },
                    }}
                  />

                </Grid>
              </Grid>

              {/* Category Multi-Select Field */}
              <Grid container spacing={2} alignItems="center" sx={{ mb: 1.5 }}>
                <Grid item xs={12} md={4}>
                  <Typography
                    variant="body1"
                    color={"#015a3b"}
                    fontSize={"12px"}
                    fontWeight={"bold"}
                    fontFamily={"SweetSansBold"}

                  >
                    Categorie
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <FormControl fullWidth>
                    <Select
                      labelId="category-label"
                      multiple
                      value={selectedCategories}
                      onChange={handleChange}
                      name="category"
                      required
                      input={<OutlinedInput label="" />}
                      renderValue={(selected) => {
                        if (selected.length === 0) {
                          return <em style={{ color: '#c2c2c2' }}>Selecteer categorie (of meerdere)</em>;
                        }

                        return selected.join(', ');
                      }}
                      sx={{
                        backgroundColor: "white",
                        height: "2.8em",

                      }}
                      displayEmpty
                    >
                      <MenuItem disabled value="">
                        <em>Selecteer categorie (of meerdere)</em>
                      </MenuItem>

                      {category.map((category) => (
                        <MenuItem
                          key={category._id}
                          value={category.name}
                          sx={{
                            backgroundColor: selectedCategories.includes(category.name) ? 'black' : 'transparent',
                            color: selectedCategories.includes(category.name) ? 'orange' : 'black',
                          }}
                        >
                          {category.name}
                        </MenuItem>
                      ))}
                    </Select>

                  </FormControl>
                </Grid>
              </Grid>

              {/* Submit Button */}
              <Grid container spacing={2} alignItems="center" sx={{ mb: 1.5 }}>
                <Grid item xs={1} md={4}></Grid>
                <Grid item xs={6} md={4} sx={{ marginLeft: { xs: "-30px", md: "0" } }}>
                  <Button
                    variant="contained"
                    type="submit"
                    fullWidth
                    sx={{
                      mt: 1,
                      backgroundColor: "#f6b732",
                      color: "#015a3b",
                      fontWeight: "bold",
                      textTransform: "capitalize",
                      fontFamily: "SweetSansBold",
                      boxShadow:'none',
                      "&:hover": { backgroundColor: "#f6b732" },
                    }}
                  >
                    {loading ? <CircularProgress size="25px" style={{ color: '#015a3b' }} /> : "Versturen"}
                  </Button>
                </Grid>
                <Grid item xs={6} md={4}></Grid>
              </Grid>
            </Box>
          </form>

        </Grid>
        <Grid
          container
          order={{ xs: 2, md: 3 }} // Reorder on mobile
          sx={{ marginTop: { xs: "-80px", md: "-90px " } }}
        >
          <Grid item xs={2} md={1} sx={{ marginLeft: { xs: "15px" } }}></Grid>
          {isSubmitted && (
            <Grid item xs={8} md={10} sx={{ zIndex: { xs: 1 } }}>
              <Box
                sx={{
                  backgroundColor: "#015a3b",
                  color: "#fff",
                  py: 2,
                  px: 3,
                  textAlign: "center",
                  borderRadius: "4px",
                  fontWeight: 'normal',
                  fontFamily: 'SweetSansRegular',
                  fontSize: '14px'
                }}
              >
                {status ?
                  `Bedankt voor je aanvraag, deze hebben we goed ontvangen en gaan we
                in behandeling nemen. Zodra jouw aanvraag is goedgekeurd, ontvang
                je van ons een e-mailbericht.`
                  :
                  errorMessage
                }
              </Box>
            </Grid>
          )}
          <Grid item xs={2} md={1}></Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AddCompany;
