import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { Grid } from '@mui/material';

const InputCompo = ({ label, placeholder, value, onChange, width, inputMode, id }) => {
  return (
    <Box
      component="form"
      sx={{
        '& label.Mui-focused': {
          color: '#0A2252', // Focus color for label
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: '#0A2252', // Focus underline color
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: '#EAEAEF', // Default border color
            border:'2px solid #EAEAEF'
          },
          '&:hover fieldset': {
            borderColor: '#0A2252', // Hover border color
          },
          '&.Mui-focused fieldset': {
            borderColor: '#0A2252', // Focus border color
          },
          '& input': {
            paddingLeft: (theme) => theme.spacing(2),
            fontSize: '16px',
            color: '#0A2252', // Text color
            fontFamily: 'SF Pro Display',
            fontWeight: '500',
          },
          height: 45,
          borderRadius: '25px',
        },
        "& .MuiInputBase-input::placeholder": {
          opacity: 1,
          color: '#84818A',
          fontSize: '14px'
        },
      }}
      noValidate
      autoComplete="no"
          >
      <Grid item xs={12} md={6}>
        <TextField
          id={id}
          label={label}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          fullWidth
          sx={{
            width: width,
          }}
          inputProps={{
            inputMode: inputMode,
            autoComplete: 'off' // Ensuring autocomplete is off
          }}
        />
      </Grid>
    </Box>
  );
}

export default InputCompo;
