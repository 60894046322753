import React from "react";
import Header from "../../Components/Header/Header";
import CompanyPage from "./AddComapny";
import Footer from "../../Components/Footer/Footer";

const AddCompanyMain = () => {
  return (
    <>
      <Header />
      <CompanyPage />
    </>
  );
};
export default AddCompanyMain;
