import React, { useState, useEffect, useCallback } from 'react';
import styled, { keyframes } from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Logoimgmain } from './Header/HeaderStyles';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import StarIcon from '../Assets/Images/star.png';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Logo from "../Assets/Images/yellowlogo-removebg-preview.png";

// Keyframes for slide animations
const slideDown = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
`;

const slideUp = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-100%);
  }
`;

// Styled components for the drawer and icons
const DrawerWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #f5a623;
  color: white;
  z-index: 1000;
  transform: translateY(${props => (props.open ? '0' : '-100%')});
  animation: ${props => (props.animate ? (props.open ? slideDown : slideUp) : 'none')} 0.5s forwards;
  border-bottom-left-radius: 75px;
  border-bottom-right-radius: 75px;
  overflow: hidden;
`;

const IconWrapper = styled.div`
  top: 14px;
  right: 10px;
  font-size: 24px;
  z-index: 1100;
  cursor: pointer;
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
`;

const IconWrapper1 = styled.div`
  position: fixed;
  top: 10px;
  right: 10px;
  font-size: 24px;
  z-index: 1100;
  cursor: pointer;
`;

const DrawerContent = styled.div`
  padding: 20px;
  padding-bottom: 60px;
  text-align: left;

  h1 {
    margin-left: 10px;
    font-size: 40px;
    font-weight: 600;
  }

  p {
    margin-left: 10px;
    color: #015a3b;
    font-size: 18px;
    font-weight: 500;
  }
`;

const BottomIconWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #fff;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
`;

const UserMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(null); // Start with null to ensure initial state management
  const [hasInteracted, setHasInteracted] = useState(false);

  // Load drawer state from local storage
  useEffect(() => {
    const storedState = localStorage.getItem('drawerOpen');
    if (storedState) {
      setIsOpen(JSON.parse(storedState));
    } else {
      setIsOpen(false); // Default to closed if not found in local storage
    }
  }, []);

  // Save drawer state to local storage
  useEffect(() => {
    if (isOpen !== null) {
      localStorage.setItem('drawerOpen', JSON.stringify(isOpen));
    }
  }, [isOpen]);

  // Toggle drawer visibility
  const toggleDrawer = useCallback(() => {
    setIsOpen(prevState => !prevState);
    setHasInteracted(true);
  }, []);

  // Close drawer when navigating away or on page refresh
  useEffect(() => {
    setIsOpen(false);
    setHasInteracted(false);
  }, [location]);

  // Initial render check
  useEffect(() => {
    if (isOpen === null) {
      setIsOpen(false); // Ensure initial state is set to avoid flickering
    }
  }, [isOpen]);

  return (
    <>
      <IconWrapper show={isOpen === false} onClick={toggleDrawer}>
        <MoreVertIcon style={{ fontSize: 30 }} />
      </IconWrapper>

      <DrawerWrapper open={isOpen} animate={hasInteracted}>
        <Box sx={{ padding: '10px' }}>
          <Logoimgmain src={Logo} onClick={() => navigate('/')} />
          {isOpen && (
            <IconWrapper1 onClick={toggleDrawer}>
              <CloseIcon style={{ fontSize: 30 }} />
            </IconWrapper1>
          )}
        </Box>
        <DrawerContent>
          <h1>Menu</h1>
          <p onClick={() => navigate('/profiel-toevoegen')}>Voeg jouw bedrijf toe</p>
          {/* <div
            style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}
            onClick={() => navigate('/favourite')}
          >
            <img src={StarIcon} alt="Star Icon" style={{ width: '20px', marginRight: '2px' }} />
            <p>Favorieten</p>
          </div> */}
          <p onClick={() => navigate('/contact')}>Contact</p>
        </DrawerContent>
        <BottomIconWrapper>
          <ExpandLessIcon style={{ color: '#f5a623', fontSize: 30 }} />
        </BottomIconWrapper>
      </DrawerWrapper>
    </>
  );
};

export default UserMenu;
