import React, { useContext, useEffect, useState } from "react";
import {
    Container,
    Grid,
    Typography,
} from "@mui/material";
import useMediaQuery from "../../Hooks/MediaQuery";

const Favourites = () => {
    const [loading, setLoading] = useState('')
    const isMobile = useMediaQuery('(min-width: 950px)');


    return (
        <Container sx={{ py: 5, paddingLeft: { md: "0px" }, width: '90%' }}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Typography
                        align="center"
                        sx={{ fontWeight: "900", color: "#015a3b", mb: 2, fontFamily: 'MontExtraBold',fontSize:'40px' }}
                    >
                        Favorieten
                    </Typography>
                    <Typography
                        variant="body2"
                        align="center"
                        color="textSecondary"
                        sx={{ mb: 4, width: isMobile ? '70%' : '90%', textAlign: 'center', alignSelf: 'center', margin: 'auto', color: "#a29390" }}
                        style={{ fontWeight: '300', fontFamily: 'SweetSansRegular', marginBottom: isMobile ? '' : '10%' }}

                    >
                        Lorem Ipsum is simply dummy text of the printing and typesetting
                        industry. Lorem Ipsum has been the industry's standard dummy text
                        ever since the 1500s, when an unknown printer took a galley of type
                        and scrambled it to make a type specimen book
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    );
};

export default Favourites;
