// // import React from "react";
// // import Header from "../../Components/Header/Header";
// // import SceneVideo from '../../Assets/Images/video_headerdi.mp4'; // Replace with your video path
// // import { CardsContainer, ContainerImageContent, Heading, SubHeading } from "./HomeStyles";
// // import Cards from "../../Components/Cards";
// // import CategoriesCompo from "../../Components/CategoriesCompo";
// // import useMediaQuery from "../../Hooks/MediaQuery";
// // import Footer from "../../Components/Footer/Footer";

// // const HomePage = () => {
// //     const isMobile = useMediaQuery('(min-width: 950px)');

// //     const backgroundContainerStyle = {
// //         position: 'relative',
// //         height: isMobile ? '75vh' : '85vh',
// //         width: '100%',

// //     };

// //     const videoStyle = {
// //         position: 'absolute',
// //         top: 0,
// //         left: 0,
// //         width: '100%',
// //         height: '100%',
// //         objectFit: 'cover', // Cover the entire container
// //         zIndex: -1, // Place behind other content
// //         filter: 'blur(2px)',
// //     };

// //     const contentWrapperStyle = {
// //         position: 'relative',
// //         zIndex: 1, // Ensure content is above the video
// //     };
// //     return (
// //         <div style={backgroundContainerStyle}>
// //             <video autoPlay loop muted style={videoStyle}>
// //                 <source src={SceneVideo} type="video/mp4" />
// //                 Your browser does not support the video tag.
// //             </video>
// //             <div style={contentWrapperStyle}>
// //                 <Header />
// //                 <ContainerImageContent>
// //                     {/* Keep your existing content structure */}
// //                     {isMobile ?
// //                         <SubHeading>Altijd op de hoogte wat er <br /> speelt bij jou in buurt!</SubHeading>
// //                         :
// //                         <SubHeading>Altijd op de hoogte<br /> wat er speelt bij <br /> jou in buurt!</SubHeading>
// //                     }
// //                 </ContainerImageContent>
// //                 <CardsContainer>
// //                     <CategoriesCompo />
// //                     {/* Add other components or sections as needed */}
// //                 </CardsContainer>
// //                 <Footer/>
// //             </div>
// //         </div>
// //     );
// // }

// // export default HomePage;


// import React from "react";
// import Header from "../../Components/Header/Header";
// import Scene from '../../Assets/Images/pexels.png';
// import { CardsContainer, ContainerImageContent, Heading, SubHeading } from "./HomeStyles";
// import Cards from "../../Components/Cards";
// import CategoriesCompo from "../../Components/CategoriesCompo";
// import useMediaQuery from "../../Hooks/MediaQuery";
// import Footer from "../../Components/Footer/Footer";

// const HomePage = () => {
//     const isMobile = useMediaQuery('(min-width: 950px)');

//     const backgroundContainerStyle = {
//         position: 'relative',
//         height: isMobile ? '73vh' : '80vh',
//         width: '100%',
//         backgroundImage: `url(${Scene})`,
//         backgroundRepeat: 'no-repeat',
//         backgroundSize: 'cover',
//         backgroundPosition: 'center',
//         opacity: 10,
//     };

//     // Adjusted style for the overall container to ensure content can start from the top
//     const contentOverlayStyle = {
//         position: 'absolute', // Absolute positioning to overlay the background
//         top: 0,
//         left: 0,
//         right: 0,
//     }

//     return (
//         <div>
//             <div style={backgroundContainerStyle}></div> {/* Background Image Container */}
//             <div style={contentOverlayStyle}>
//                 <Header />
//                 <ContainerImageContent>
//                     {isMobile ?
//                         <SubHeading>Altijd op de hoogte wat er <br /> speelt bij jou in buurt!</SubHeading>
//                         :
//                         <SubHeading>Altijd op de hoogte<br /> wat er speelt bij <br /> jou in buurt!</SubHeading>
//                     }
//                 </ContainerImageContent>
//                 <CardsContainer>
//                     <CategoriesCompo />
//                 </CardsContainer>
//             </div>

//         </div>
//     );
// }

// export default HomePage;



// import React from "react";
// import Header from "../../Components/Header/Header";
// import Scene from '../../Assets/Images/pexels.png';
// import { CardsContainer, ContainerImageContent, SubHeading } from "./HomeStyles";
// import Cards from "../../Components/Cards";
// import CategoriesCompo from "../../Components/CategoriesCompo";
// import useMediaQuery from "../../Hooks/MediaQuery";
// import Footer from "../../Components/Footer/Footer";
// import { Container, Grid } from "@mui/material";

// const HomePage = () => {
//     const isMobile = useMediaQuery('(min-width: 950px)');

//     const backgroundContainerStyle = {
//         position: 'relative',
//         width: '100%',
//         backgroundImage: `url(${Scene})`,
//         backgroundRepeat: 'no-repeat',
//         backgroundSize: 'cover',
//         backgroundPosition: 'center',
//         appearance: 'none', 
//         opacity: 0.62,
//         height: {
//             xs: '60vh',  // Small screen (extra small devices)
//             sm: '70vh',  // Small screen (tablets)
//             md: '75vh',  // Medium screen (laptops)
//             lg: '80vh',  // Large screen (desktops)
//             xl: '85vh',  // Extra large screens
//           }
//     };
    

// const contentOverlayStyle = {
//     position: 'absolute',
//     top: 0,
//     left: 0,
//     right: 0,
// };

// return (
//     <div>
//         <div style={backgroundContainerStyle}></div> {/* Background Image Container */}
//         <div style={contentOverlayStyle}>
//             <Header />
//             <Container
//                 sx={{
//                     paddingLeft: { md: "0px" },
//                     paddingRight: { md: "0px" },
//                     // width: isMobile ? "90%" : '95%', 
//                     maxWidth: { xs: "100%", sm: "95%", md: "90%", lg: "90%",xl:"90%" },  // Different widths for different breakpointsW
//                     padding: '0px',    // No padding inside the container
//                     margin: '0 auto',  // Center the container horizontally
//                 }}
//             >
//                 <ContainerImageContent>
//                     {isMobile ?
//                         <SubHeading>Altijd op de hoogte wat er <br /> speelt bij jou in buurt!</SubHeading>
//                         :
//                         <SubHeading>Altijd op de hoogte<br /> wat er speelt bij <br /> jou in buurt!</SubHeading>
//                     }
//                 </ContainerImageContent>
//                 <Grid container justifyContent="center">
//                     <Grid item xs={12} md={12}>
//                         <CardsContainer>
//                             <CategoriesCompo />
//                         </CardsContainer>
//                     </Grid>
//                 </Grid>
//             </Container>
//         </div>
//     </div>
// );
// };

// export default HomePage;
import React from "react";
import Header from "../../Components/Header/Header";
import Scene from '../../Assets/Images/yellowback.png';
import { CardsContainer, ContainerImageContent, SubHeading } from "./HomeStyles";
import Cards from "../../Components/Cards";
import CategoriesCompo from "../../Components/CategoriesCompo";
import useMediaQuery from "../../Hooks/MediaQuery";
import Footer from "../../Components/Footer/Footer";
import { Container, Grid, Box } from "@mui/material";

const HomePage = () => {
    const isMobile = useMediaQuery('(min-width: 950px)');

    return (
        <div>
            <Box
                sx={{
                    position: 'relative',
                    width: '100%',
                    backgroundImage: `url(${Scene})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    appearance: 'none',
                    opacity: 1,
                    height: {
                        xs: '70vh',  // Small screen (extra small devices)
                        sm: '50vh',  // Small screen (tablets)
                        md: '65vh',  // Medium screen (laptops)
                        lg: '65vh',  // Large screen (desktops)
                    },
                }}
            />
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        right: 0,
                    }}
                >
                    <Header />
                    <Container
                        sx={{
                            paddingLeft: { md: "0px" },
                            paddingRight: { md: "0px" },
                            maxWidth: { xs: "95%", sm: "90%", md: "90%", lg: "90%", xl: "90%" },
                            padding: '0px',
                            margin: '0 auto',
                        }}
                    >
                        <ContainerImageContent>
                            {isMobile ?
                                <SubHeading>Altijd op de hoogte wat er <br /> speelt bij jou in de buurt!</SubHeading>
                                :
                                <SubHeading>Altijd op de hoogte<br /> wat er speelt bij <br /> jou in de buurt!</SubHeading>
                            }
                        </ContainerImageContent>
                        <Grid container justifyContent="center">
                            <Grid item xs={12} md={12}>
                                <CardsContainer>
                                    <CategoriesCompo />
                                </CardsContainer>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
        </div>
    );
};

export default HomePage;
