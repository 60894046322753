import React from "react";
import Header from "../../Components/Header/Header";
import ContactPage from "./ContactUs";
import Footer from "../../Components/Footer/Footer";


const ContactMainCompo = () => {
    return (
        <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
            <Header />
            <div style={{ flex: 1 }}>
            <ContactPage />
            </div>
        </div>
    )
}
export default ContactMainCompo;