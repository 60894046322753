import React from 'react';
import './FooterStyles.css'; // Assuming you're using an external CSS file

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <p>© 2024 Alles in Tubbergen | <a href="">Algemene voorwaarden</a> | <a href="">Privacy policy</a></p>
      </div>
    </footer>
  );
};

export default Footer;
