// import * as React from 'react';
// import { Box, Card, CardActions, CardContent, CardHeader, IconButton, Typography, Avatar, CardMedia, Button, Skeleton } from '@mui/material';
// import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
// import { BackImgHome, CardsContainerPosi, CardsSubContainer, ShowMore } from '../Pages/HomeMain/HomeStyles';
// import { useNavigate } from 'react-router-dom';
// import StarsIcon from '@mui/icons-material/Stars';
// import useMediaQuery from '../Hooks/MediaQuery';
// import FB from '../Assets/Images/facebook.png';
// import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

// import CardMenu from './CardMenu';
// import NoImage from '../Assets/Images/no-image.jpg';
// import './Cards.css';
// import CardSkeleton from './CardSkeleton';
// import StarIcon from '@mui/icons-material/Star';
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
// import { ArrowIcCard, BackLinkCard, StarImage, TextLinkCard, UnderlineCard } from './Header/HeaderStyles';
// import FilledStar from '../Assets/Images/starfill.png';
// import NotFilledStar from '../Assets/Images/starnotfill.png';
// import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
// const ButtonStyle = {
//     color: '#fff',
//     borderRadius: 35,
//     padding: '10px 20px',
//     marginTop: '2%',
//     backgroundColor: '#F7B324',
//     textDecoration: 'none',
//     textTransform: 'none',
//     height: '38px',
//     fontFamily: 'SweetSansProThinItalic',
//     boxShadow: 'none',
//     justifyContent: 'space-between',

// };
// export default function Cards({ data, isLoading }) {
//     const navigate = useNavigate();
//     const [visibleCount, setVisibleCount] = React.useState(10);
//     const [loading, setLoading] = React.useState(false);
//     const observer = React.useRef();
//     const [scrollPosition, setScrollPosition] = React.useState(0);
//     const isMobile = useMediaQuery('(min-width: 950px)');
//     const [imageError, setImageError] = React.useState(false);
//     const [hasMoreItems, setHasMoreItems] = React.useState(true);

//     const [isStarFilled, setIsStarFilled] = React.useState(false); // State to track the star's fill status
//     // Toggle star fill state on click
//     const handleStarClick = () => {
//         setIsStarFilled(prevState => !prevState);
//     };
//     const lastCardRef = React.useCallback((node) => {
//         if (loading) return;
//         if (observer.current) observer.current.disconnect();
//         observer.current = new IntersectionObserver(entries => {
//             if (entries[0].isIntersecting && hasMoreItems) {
//                 setLoading(true);
//                 setTimeout(() => {
//                     setVisibleCount(prevCount => prevCount + 10);
//                     setLoading(false);
//                 }, 1500); // Simulate a loading delay
//             }
//         });
//         if (node) observer.current.observe(node);
//     }, [loading, hasMoreItems]);

//     React.useEffect(() => {
//         // Check if there are more items to load
//         if (data.length > visibleCount) {
//             setHasMoreItems(true);
//         } else {
//             setHasMoreItems(false);
//         }

//     }, [data, visibleCount]);


//     function truncateText(text, maxLength) {
//         return text?.length && text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
//     }

//     function formatDate(isoString) {
//         const date = new Date(isoString);
//         const dateOptions = { day: 'numeric', month: 'long', year: 'numeric' };
//         const formattedDate = date.toLocaleDateString('en-GB', dateOptions);
//         const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: false };
//         const formattedTime = date.toLocaleTimeString('en-GB', timeOptions);
//         return `${formattedDate} om ${formattedTime}`;
//     }

//     if (isLoading) {
//         return (
//             <CardSkeleton />
//         );
//     }
//     return (
//         <>
//             <div style={{ marginBottom: '10%' }}>
//                 <ResponsiveMasonry gutter="15px" margin="10px"
//                     columnsCountBreakPoints={{ 300: 2, 500: 3, 700: 4, 900: 5 }}

//                 >
//                     <Masonry gutter="20px">
//                         {data
//                             .slice(0, visibleCount)
//                             .filter(item => item.latestPost && item.latestPost?.length > 0 &&
//                                 item.latestPost[0] &&
//                                 !item.latestPost[0]?.error && // Exclude if there's an error
//                                 item.latestPost[0]?.openGraph?.url !== "https://www.facebook.com/"

//                             )
//                             .map((data, index) => (
//                                 <React.Fragment key={index}>
//                                     <Card
//                                         ref={index === visibleCount - 1 ? lastCardRef : null} // Attach observer to the last visible card

//                                         sx={{
//                                             display: 'block',
//                                             marginBottom: '0%',
//                                             borderRadius: '5px',
//                                             position: 'relative',
//                                             cursor: 'pointer',
//                                             overflow: 'hidden',
//                                             backgroundColor: '#F6F6F6',
//                                             boxShadow: '0 2px 4px rgba(0, 0, 0, 0)',
//                                             '&:hover .overlay, &:hover .cardActions': {
//                                                 opacity: 1,
//                                                 visibility: 'visible',


//                                             },
//                                             '&:hover .overlay': {
//                                                 backgroundColor: '#2A0800',
//                                                 opacity: '0.60'
//                                             },
//                                             '@media (max-width: 950px)': {
//                                                 flex: '1 0 auto',
//                                                 maxWidth: '100%'
//                                             }
//                                         }}
//                                         elevation={1}
//                                         onClick={() => window.open(`${data.latestPost && data.latestPost[0].user?.profileUrl}`, '_blank')} // Opens a new tab

//                                     >
//                                         <Box className="overlay" sx={{
//                                             position: 'absolute',
//                                             top: 0,
//                                             left: 0,
//                                             width: '100%',
//                                             height: '100%',
//                                             backgroundColor: 'rgba(0, 0, 0, 0.5)',
//                                             opacity: 0,
//                                             visibility: 'hidden',
//                                             transition: 'opacity 0.3s, visibility 0s linear 0.3s',
//                                             zIndex: 2,
//                                         }} />
//                                         <CardHeader
//                                             avatar={
//                                                 <BackImgHome style={{
//                                                     marginLeft: '-10%'
//                                                 }}>
//                                                     <Avatar sx={{ width: isMobile ? "90%" : 20, height: isMobile ? "90%" : 20, objectFit: 'cover' }} src={data.latestPost && data.latestPost[0].user?.profilePic} alt="Avatar" />
//                                                 </BackImgHome>
//                                             }
//                                             title={
//                                                 <Typography sx={{
//                                                     fontWeight: 'bold',
//                                                     fontSize: isMobile ? '12px' : '11px',
//                                                     fontFamily: 'SweetSansBold',
//                                                     color: '#2A0800',
//                                                     maxWidth: '20ch', // Example: truncate text after 25 characters
//                                                     overflow: 'hidden',
//                                                     textOverflow: 'ellipsis',
//                                                     marginLeft: isMobile ? '-5%' : '-5%'
//                                                 }}>
//                                                     {truncateText(data.latestPost && data.latestPost[0].user?.name, isMobile ? 25 : 25)}
//                                                 </Typography>
//                                             }
//                                             subheader={
//                                                 <Typography sx={{
//                                                     color: '#2A0800',
//                                                     fontSize: isMobile ? '8px' : '6px',
//                                                     fontFamily: 'SweetSansRegular',
//                                                     marginLeft: isMobile ? '-5%' : '-5%'

//                                                 }}>
//                                                     {formatDate(data.latestPost && data.latestPost[0].time)}
//                                                 </Typography>
//                                             }
//                                             action={
//                                                 // <Avatar
//                                                 //     sx={{
//                                                 //         width: 15,
//                                                 //         height: 15,
//                                                 //         objectFit: 'cover',
//                                                 //         position: 'absolute',
//                                                 //         right: 5,
//                                                 //         top: 5
//                                                 //     }}
//                                                 //     src={FB}
//                                                 //     alt="Right Side Image"
//                                                 // />
//                                                 <FacebookOutlinedIcon
//                                                     sx={{
//                                                         width: 18,
//                                                         height: 18,
//                                                         objectFit: 'cover',
//                                                         position: 'absolute',
//                                                         right: 5,
//                                                         top: 5,
//                                                         color: '#0866ff'
//                                                     }}
//                                                 />
//                                             }
//                                             sx={{
//                                                 backgroundColor: '#F6F6F6',
//                                                 height: '25px',
//                                                 padding: '14px',
//                                                 marginTop: '5px'
//                                             }}
//                                         />
//                                         {/* <CardContent sx={{
//                                         minHeight: 'auto',
//                                     }}>
//                                         <Typography fontSize={'12px'} color="#2A0800" fontWeight={'300'} fontFamily={'Segoeui'}
//                                             style={{
//                                                 maxWidth: '55ch', // Example: truncate text after 25 characters
//                                                 overflow: 'hidden',
//                                                 textOverflow: 'ellipsis',
//                                                 whiteSpace: 'pre-line',
//                                             }}
//                                         >
//                                             {data.latestPost[0] && data.latestPost[0]?.text}
//                                         </Typography>
//                                     </CardContent> */}
//                                         <CardContent sx={{ minHeight: 'auto' }}>
//                                             <Typography
//                                                 fontSize={'12px'}
//                                                 color="#2A0800"
//                                                 fontWeight={'300'}
//                                                 fontFamily={'Segoeui'}
//                                                 style={{
//                                                     maxWidth: '55ch', // Example: truncate text after 55 characters
//                                                     overflow: 'hidden',
//                                                     textOverflow: 'ellipsis',
//                                                     whiteSpace: 'pre-line', // This allows \n to be converted to line breaks
//                                                 }}
//                                             >
//                                                 {data.latestPost[0]?.text?.split(/(#[^\s]+)/g).map((part, index) =>
//                                                     part.startsWith('#') ? (
//                                                         <span key={index} style={{ color: '#0064D1' }}>{part}</span>
//                                                     ) : (
//                                                         part
//                                                     )
//                                                 )}
//                                             </Typography>
//                                         </CardContent>

//                                         {/* <CardMedia
//                                         component="img"
//                                         image={
//                                             (data.latestPost && data.latestPost[0] && (
//                                                 data.latestPost[0].sharedPost && data.latestPost[0].sharedPost.media && data.latestPost[0].sharedPost.media.length > 0
//                                                     ? (data.latestPost[0].sharedPost.media[0].thumbnail || data.latestPost[0].sharedPost.media[1]?.thumbnail)
//                                                     :
//                                                     data.latestPost[0].media && data.latestPost[0].media.length > 0 &&
//                                                         data.latestPost[0].media[0].two_photos_subattachments &&
//                                                         data.latestPost[0].media[0].two_photos_subattachments.nodes &&
//                                                         data.latestPost[0].media[0].two_photos_subattachments.nodes.length > 0
//                                                         ? data.latestPost[0].media[0].two_photos_subattachments.nodes[0].media.image.uri
//                                                         :
//                                                         data.latestPost[0].media && data.latestPost[0].media[0].header_photos_subattachments &&
//                                                             data.latestPost[0].media[0].header_photos_subattachments.nodes &&
//                                                             data.latestPost[0].media[0].header_photos_subattachments.nodes.length > 0
//                                                             ? data.latestPost[0].media[0].header_photos_subattachments.nodes[0].media.image.uri
//                                                             : data.latestPost[0].media && data.latestPost[0].media.length > 0
//                                                                 ? (data.latestPost[0].media[0].thumbnail || data.latestPost[0].media[1]?.thumbnail)

//                                                                 : <></>
//                                             ))  
//                                         }
//                                         style={{
//                                             objectFit: isMobile ? 'cover' : 'contain',
//                                             width: isMobile ? '90%' : '90%',
//                                             margin: 'auto',
//                                             borderRadius: '5px',
//                                             marginBottom: '12px'
//                                         }}
//                                     /> */}

//                                         {
//                                             (data.latestPost && data.latestPost[0] && (
//                                                 (data.latestPost[0].sharedPost && data.latestPost[0].sharedPost.media && data.latestPost[0].sharedPost.media.length > 0 &&
//                                                     (data.latestPost[0].sharedPost.media[0].thumbnail || data.latestPost[0].sharedPost.media[1]?.thumbnail)
//                                                 ) ||
//                                                 (data.latestPost[0].media && data.latestPost[0].media.length > 0 &&
//                                                     (data.latestPost[0].media[0].two_photos_subattachments?.nodes?.length > 0
//                                                         ? data.latestPost[0].media[0].two_photos_subattachments.nodes[0].media.image.uri
//                                                         : data.latestPost[0].media[0].header_photos_subattachments?.nodes?.length > 0
//                                                             ? data.latestPost[0].media[0].header_photos_subattachments.nodes[0].media.image.uri
//                                                             : data.latestPost[0].media[0].thumbnail || data.latestPost[0].media[1]?.thumbnail
//                                                     )
//                                                 )
//                                             )) ? (
//                                                 <CardMedia
//                                                     component="img"
//                                                     image={
//                                                         (data.latestPost[0].sharedPost && data.latestPost[0].sharedPost.media && data.latestPost[0].sharedPost.media.length > 0
//                                                             ? (data.latestPost[0].sharedPost.media[0].thumbnail || data.latestPost[0].sharedPost.media[1]?.thumbnail)
//                                                             : data.latestPost[0].media && data.latestPost[0].media.length > 0 &&
//                                                                 data.latestPost[0].media[0].two_photos_subattachments?.nodes?.length > 0
//                                                                 ? data.latestPost[0].media[0].two_photos_subattachments.nodes[0].media.image.uri
//                                                                 : data.latestPost[0].media && data.latestPost[0].media[0].header_photos_subattachments?.nodes?.length > 0
//                                                                     ? data.latestPost[0].media[0].header_photos_subattachments.nodes[0].media.image.uri
//                                                                     : data.latestPost[0].media[0].thumbnail || data.latestPost[0].media[1]?.thumbnail
//                                                         )
//                                                     }
//                                                     onError={(e) => { e.target.style.display = 'none'; }} // Hide image if load fails
//                                                     style={{
//                                                         objectFit: isMobile ? 'cover' : 'contain',
//                                                         width: isMobile ? '90%' : '90%',
//                                                         margin: 'auto',
//                                                         borderRadius: '5px',
//                                                         marginBottom: '12px'
//                                                     }}
//                                                 />
//                                             ) : null
//                                         }



//                                         {/* <CardActions
//                                         className="cardActions"
//                                         disableSpacing
//                                         sx={{
//                                             display: 'flex',
//                                             justifyContent: 'space-between', 
//                                             alignItems: 'center',
//                                             position: 'absolute',
//                                             bottom: 10,
//                                             left: 0,
//                                             width: '90%',
//                                             zIndex: 3,
//                                             visibility: 'hidden',
//                                             opacity: 1, 
//                                             transition: 'opacity 0.3s, visibility 0s linear 0.3s',
//                                             alignSelf: 'center'
//                                         }}
//                                     >
//                                         <Box
//                                             sx={{
//                                                 backgroundColor: '#F7B324', 
//                                                 width: '35px',
//                                                 height: '35px',
//                                                 borderRadius: '50%', 
//                                                 display: 'flex',
//                                                 justifyContent: 'center',
//                                                 alignItems: 'center',
//                                                 marginLeft: '4%'
//                                             }}
//                                             onClick={handleStarClick} 

//                                         >
//                                             <StarImage src={isStarFilled ? FilledStar : NotFilledStar} alt="Star" />
//                                         </Box>
//                                         <BackLinkCard as="a" href={data.latestPost && data.latestPost[0].user?.profileUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }} >
//                                             <TextLinkCard>Bekijk bericht</TextLinkCard>
//                                             <ArrowForwardIcon style={{ fontSize: '14px', marginLeft: '5px', marginBottom: '-3%' }} />
//                                             <UnderlineCard></UnderlineCard>
//                                         </BackLinkCard>
//                                     </CardActions> */}
//                                         <CardActions
//                                             className="cardActions"
//                                             disableSpacing
//                                             sx={{
//                                                 display: 'flex',
//                                                 flexDirection: 'column',
//                                                 alignItems: 'center',
//                                                 position: 'absolute', // Change to relative
//                                                 bottom: 'auto', // Remove bottom positioning
//                                                 top: '50%', // Center vertically
//                                                 transform: 'translateY(-50%)', // Adjust for true center
//                                                 width: '100%',
//                                                 zIndex: 3,
//                                                 visibility: 'hidden', // Make sure it's visible
//                                                 padding: '10px',
//                                                 opacity: 1,
//                                                 transition: 'opacity 0.3s, visibility 0s linear 0.3s',
//                                             }}
//                                         >
//                                             <a href={data.latestPost && data.latestPost[0].user?.profileUrl} target="_blank" rel="noopener noreferrer" >
//                                                 <Button
//                                                     variant="contained"
//                                                     endIcon={<ArrowForwardIcon />}
//                                                     style={{
//                                                         ...ButtonStyle, width: isMobile ? '100%' : '100%',
//                                                         fontSize: isMobile ? '13px' : '9px',
//                                                         fontWeight: '300'
//                                                     }}
//                                                 >
//                                                     Bekijk bericht
//                                                 </Button>
//                                             </a>
//                                         </CardActions>
//                                     </Card>
//                                     {/* {!isMobile &&
//                                     <IconButton sx={{ float: 'right', marginLeft: 'auto', alignItems: 'flex-end', justifyContent: 'flex-end' }}>
//                                         <CardMenu />
//                                     </IconButton>
//                                 } */}
//                                 </React.Fragment>
//                             ))}
//                         {/* {loading && hasMoreItems && Array.from(new Array(8)).map((_, index) => (
//                         <Card key={index} style={{ boxShadow: 'none' }}>
//                             <Skeleton variant="rectangular" width="265px" height={200} style={{ boxShadow: 'none' }} />
//                         </Card>
//                     ))} */}
//                         {loading && hasMoreItems && (

//                             Array.from(new Array(10)).map((_, index) => (
//                                 <Card key={index} sx={{ boxShadow: 'none', width: '100%' }}>
//                                     <Skeleton variant="rectangular" width="100%" height={400} style={{ boxShadow: 'none' }} />
//                                 </Card>
//                             ))
//                         )}
//                     </Masonry>
//                 </ResponsiveMasonry>
//                 {!hasMoreItems && (
//                     <Typography variant="body2" color="text.secondary" style={{ textAlign: 'center', marginBottom: '6%' }}>
//                         Geen berichten meer beschikbaar.
//                     </Typography>
//                 )}
//             </div>
//         </>
//     );
// }

import * as React from 'react';
import { Box, Card, CardActions, CardContent, CardHeader, IconButton, Typography, Avatar, CardMedia, Button, Skeleton } from '@mui/material';
import { BackImgHome, CardsContainerPosi, CardsSubContainer, ShowMore } from '../Pages/HomeMain/HomeStyles';
import { useNavigate } from 'react-router-dom';
import StarsIcon from '@mui/icons-material/Stars';
import useMediaQuery from '../Hooks/MediaQuery';
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import './Cards.css';
import CardSkeleton from './CardSkeleton';
import StarIcon from '@mui/icons-material/Star';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { ArrowIcCard, BackLinkCard, StarImage, TextLinkCard, UnderlineCard } from './Header/HeaderStyles';
import FilledStar from '../Assets/Images/starfill.png';
import NotFilledStar from '../Assets/Images/starnotfill.png';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
const ButtonStyle = {
    color: '#fff',
    borderRadius: 35,
    padding: '10px 20px',
    marginTop: '2%',
    backgroundColor: '#F7B324',
    textDecoration: 'none',
    textTransform: 'none',
    height: '38px',
    fontFamily: 'SweetSansProThinItalic',
    boxShadow: 'none',
    justifyContent: 'space-between',

};
export default function Cards({ data, isLoading }) {
    const navigate = useNavigate();
    const [visibleCount, setVisibleCount] = React.useState(10);
    const [loading, setLoading] = React.useState(false);
    const observer = React.useRef();
    const [scrollPosition, setScrollPosition] = React.useState(0);
    const isMobile = useMediaQuery('(min-width: 950px)');
    const [imageError, setImageError] = React.useState(false);
    const [hasMoreItems, setHasMoreItems] = React.useState(true);

    const [isStarFilled, setIsStarFilled] = React.useState(false); // State to track the star's fill status
    // Toggle star fill state on click
    const handleStarClick = () => {
        setIsStarFilled(prevState => !prevState);
    };
    const lastCardRef = React.useCallback((node) => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMoreItems) {
                setLoading(true);
                setTimeout(() => {
                    setVisibleCount(prevCount => prevCount + 10);
                    setLoading(false);
                }, 1500); // Simulate a loading delay
            }
        });
        if (node) observer.current.observe(node);
    }, [loading, hasMoreItems]);

    React.useEffect(() => {
        // Check if there are more items to load
        if (data.length > visibleCount) {
            setHasMoreItems(true);
        } else {
            setHasMoreItems(false);
        }

    }, [data, visibleCount]);


    function truncateText(text, maxLength) {
        return text?.length && text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
    }

    function formatDate(isoString) {
        const date = new Date(isoString);
        const dateOptions = { day: 'numeric', month: 'long', year: 'numeric' };
        const formattedDate = date.toLocaleDateString('en-GB', dateOptions);
        const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: false };
        const formattedTime = date.toLocaleTimeString('en-GB', timeOptions);
        return `${formattedDate} om ${formattedTime}`;
    }

    if (isLoading) {
        return (
            <CardSkeleton />
        );
    }
    return (
        <>
            <div style={{ marginBottom: '10%' }}>
                <ResponsiveMasonry gutter="15px" margin="10px"
                  columnsCountBreakPoints={{
                    200: 2,    // Extra small screens, like small mobile devices
                    500: 2,    // Small mobile screens
                    750: 3,    // Medium devices, like tablets
                    900: 3,    // Larger tablets and smaller desktops
                    1200: 4,   // Regular desktops and larger
                    1600: 5    // Extra large monitors, widescreens
                }}
                >
                    <Masonry gutter="20px">
                        {data
                            .slice(0, visibleCount)
                            .map((data, index) => (
                                <React.Fragment key={index}>
                                    <Card
                                        ref={index === visibleCount - 1 ? lastCardRef : null} // Attach observer to the last visible card

                                        sx={{
                                            display: 'block',
                                            marginBottom: '0%',
                                            borderRadius: '5px',
                                            position: 'relative',
                                            cursor: 'pointer',
                                            overflow: 'hidden',
                                            backgroundColor: '#F6F6F6',
                                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0)',
                                            '&:hover .overlay, &:hover .cardActions': {
                                                opacity: 1,
                                                visibility: 'visible',


                                            },
                                            '&:hover .overlay': {
                                                backgroundColor: '#2A0800',
                                                opacity: '0.60'
                                            },
                                            '@media (max-width: 950px)': {
                                                flex: '1 0 auto',
                                                maxWidth: '100%'
                                            }
                                        }}
                                        elevation={1}
                                        onClick={() => window.open(`${data.latestPost && data.latestPost[0].user?.profileUrl}`, '_blank')} // Opens a new tab

                                    >
                                        <Box className="overlay" sx={{
                                            position: 'absolute',
                                            top: 0,
                                            left: 0,
                                            width: '100%',
                                            height: '100%',
                                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                            opacity: 0,
                                            visibility: 'hidden',
                                            transition: 'opacity 0.3s, visibility 0s linear 0.3s',
                                            zIndex: 2,
                                        }} />
                                        <CardHeader
                                            avatar={
                                                <BackImgHome style={{
                                                    marginLeft: '-10%'
                                                }}>
                                                    <Avatar sx={{ width: isMobile ? "90%" : 20, height: isMobile ? "90%" : 20, objectFit: 'cover' }} src={data.latestPost && data.latestPost[0].user?.profilePic} alt="Avatar" />
                                                </BackImgHome>
                                            }
                                            title={
                                                <Typography sx={{
                                                    fontWeight: 'bold',
                                                    fontSize: isMobile ? '12px' : '11px',
                                                    fontFamily: 'SweetSansBold',
                                                    color: '#2A0800',
                                                    maxWidth: '40ch', // Example: truncate text after 25 characters
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    marginLeft: isMobile ? '-5%' : '-5%'
                                                }}>
                                                    {truncateText(data.latestPost && data.latestPost[0].user?.name, isMobile ? 40 : 25)}
                                                </Typography>
                                            }
                                            subheader={
                                                <Typography sx={{
                                                    color: '#2A0800',
                                                    fontSize: isMobile ? '8px' : '6px',
                                                    fontFamily: 'SweetSansRegular',
                                                    marginLeft: isMobile ? '-5%' : '-5%'

                                                }}>
                                                    {formatDate(data.latestPost && data.latestPost[0].time)}
                                                </Typography>
                                            }
                                            action={
                                                // <Avatar
                                                //     sx={{
                                                //         width: 15,
                                                //         height: 15,
                                                //         objectFit: 'cover',
                                                //         position: 'absolute',
                                                //         right: 5,
                                                //         top: 5
                                                //     }}
                                                //     src={FB}
                                                //     alt="Right Side Image"
                                                // />
                                                <FacebookOutlinedIcon
                                                    sx={{
                                                        width: 18,
                                                        height: 18,
                                                        objectFit: 'cover',
                                                        position: 'absolute',
                                                        right: 5,
                                                        top: 3,
                                                        color: '#0866ff'
                                                    }}
                                                />
                                            }
                                            sx={{
                                                backgroundColor: '#F6F6F6',
                                                height: '25px',
                                                padding: '14px',
                                                marginTop: '5px'
                                            }}
                                        />
                                        <CardContent sx={{ minHeight: 'auto' }}>
                                            <Typography
                                                fontSize={'12px'}
                                                color="#2A0800"
                                                fontWeight={'300'}
                                                fontFamily={'Segoeui'}
                                                style={{
                                                    maxWidth: '55ch', // Example: truncate text after 55 characters
                                                    overflow: 'hidden',
                                                    textOverflow: 'ellipsis',
                                                    whiteSpace: 'pre-line', // This allows \n to be converted to line breaks
                                                }}
                                            >
                                                {data.latestPost[0]?.text?.split(/(#[^\s]+)/g).map((part, index) =>
                                                    part.startsWith('#') ? (
                                                        <span key={index} style={{ color: '#0064D1' }}>{part}</span>
                                                    ) : (
                                                        part
                                                    )
                                                )}
                                            </Typography>
                                        </CardContent>

                                        {
                                            (data.latestPost && data.latestPost[0] && (
                                                (data.latestPost[0].sharedPost && data.latestPost[0].sharedPost.media && data.latestPost[0].sharedPost.media.length > 0 &&
                                                    (data.latestPost[0].sharedPost.media[0].thumbnail || data.latestPost[0].sharedPost.media[1]?.thumbnail)
                                                ) ||
                                                (data.latestPost[0].media && data.latestPost[0].media.length > 0 &&
                                                    (data.latestPost[0].media[0].two_photos_subattachments?.nodes?.length > 0
                                                        ? data.latestPost[0].media[0].two_photos_subattachments.nodes[0].media.image.uri
                                                        : data.latestPost[0].media[0].header_photos_subattachments?.nodes?.length > 0
                                                            ? data.latestPost[0].media[0].header_photos_subattachments.nodes[0].media.image.uri
                                                            : data.latestPost[0].media[0].thumbnail || data.latestPost[0].media[1]?.thumbnail
                                                    )
                                                )
                                            )) ? (
                                                <CardMedia
                                                    component="img"
                                                    image={
                                                        (data.latestPost[0].sharedPost && data.latestPost[0].sharedPost.media && data.latestPost[0].sharedPost.media.length > 0
                                                            ? (data.latestPost[0].sharedPost.media[0].thumbnail || data.latestPost[0].sharedPost.media[1]?.thumbnail)
                                                            : data.latestPost[0].media && data.latestPost[0].media.length > 0 &&
                                                                data.latestPost[0].media[0].two_photos_subattachments?.nodes?.length > 0
                                                                ? data.latestPost[0].media[0].two_photos_subattachments.nodes[0].media.image.uri
                                                                : data.latestPost[0].media && data.latestPost[0].media[0].header_photos_subattachments?.nodes?.length > 0
                                                                    ? data.latestPost[0].media[0].header_photos_subattachments.nodes[0].media.image.uri
                                                                    : data.latestPost[0].media[0].thumbnail || data.latestPost[0].media[1]?.thumbnail
                                                        )
                                                    }
                                                    onError={(e) => { e.target.style.display = 'none'; }} // Hide image if load fails
                                                    style={{
                                                        objectFit: isMobile ? 'cover' : 'cover',
                                                        width: isMobile ? '90%' : '90%',
                                                        margin: 'auto',
                                                        borderRadius: '5px',
                                                        marginBottom: '12px'
                                                    }}
                                                />
                                            ) : null
                                        }
                                        <CardActions
                                            className="cardActions"
                                            disableSpacing
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'column',
                                                alignItems: 'center',
                                                position: 'absolute', // Change to relative
                                                bottom: 'auto', // Remove bottom positioning
                                                top: '50%', // Center vertically
                                                transform: 'translateY(-50%)', // Adjust for true center
                                                width: '100%',
                                                zIndex: 3,
                                                visibility: 'hidden', // Make sure it's visible
                                                padding: '10px',
                                                opacity: 1,
                                                transition: 'opacity 0.3s, visibility 0s linear 0.3s',
                                            }}
                                        >
                                            <a href={data.latestPost && data.latestPost[0].user?.profileUrl} target="_blank" rel="noopener noreferrer" >
                                                <Button
                                                    variant="contained"
                                                    endIcon={<ArrowForwardIcon />}
                                                    style={{
                                                        ...ButtonStyle, width: isMobile ? '100%' : '100%',
                                                        fontSize: isMobile ? '13px' : '9px',
                                                        fontWeight: '300'
                                                    }}
                                                >
                                                    Bekijk bericht
                                                </Button>
                                            </a>
                                        </CardActions>
                                    </Card>
                                </React.Fragment>
                            ))}
                        {loading && hasMoreItems && (

                            Array.from(new Array(10)).map((_, index) => (
                                <Card key={index} sx={{ boxShadow: 'none', width: '100%' }}>
                                    <Skeleton variant="rectangular" width="100%" height={400} style={{ boxShadow: 'none' }} />
                                </Card>
                            ))
                        )}
                    </Masonry>
                </ResponsiveMasonry>
                {!hasMoreItems && (
                    <Typography variant="body2" color="text.secondary" style={{ textAlign: 'center', marginBottom: '6%',marginTop:'3%' }}>
                        Geen berichten meer beschikbaar.
                    </Typography>
                )}
            </div>
        </>
    );
}

