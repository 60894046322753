export const CardsData = [
    {
        id:1,
        title:'De Manderveense Aarbei',
        category:'Winkels',
        desc:"This impressive paella is a perfect party dish and a fun meal to cook together with your guests. Add 1 cup of frozen peas along with the mussels, if you like.",

    },
    {
        id:2,
        title:'TVC28',
        img:require('../Assets/Images/SetsaLogo.png'),
        category:'Bedrijven',
        desc:"This impressive paella is a perfect party dish and a fun meal to cook together with your guests. Add 1 cup of frozen peas along with the mussels, if you like.",
        
    },
    {
        id:3,
        title:'C.V. De Kroegtijgers',
        img:require('../Assets/Images/SetsaLogo.png'),
        category:'Winkels',
        desc:"This impressive paella is a perfect party dish and a fun meal to cook together with your guests. Add 1 cup of frozen peas along with the mussels, if you like.",
        
    },
    {
        id:4,
        title:'De Manderveense Aarbei',
        category:'Verenigingen',
        desc:"This impressive paella is a perfect party dish and a fun meal to cook together with your guests. Add 1 cup of frozen peas along with the mussels, if you like.",
        
    },
    {
        id:5,
        title:'C.V. De Kroegtijgers',
        img:require('../Assets/Images/SetsaLogo.png'),
        category:'Horeca',
        desc:"This impressive paella is a perfect party dish and a fun meal to cook together with your guests. Add 1 cup of frozen peas along with the mussels, if you like.",
        
    },
    {
        id:6,
        title:'TVC28',
        img:require('../Assets/Images/SetsaLogo.png'),
        category:'Evenementen',
        desc:"This impressive paella is a perfect party dish and a fun meal to cook together with your guests. Add 1 cup of frozen peas along with the mussels, if you like.",
        
    }
]

export const ButtonTextData =[
    {
        id:2,
        name:'Winkels'
    },
    {
        id:3,
        name:'Horeca'
    },
    {
        id:4,
        name:'Verenigingen'
    },
    {
        id:5,
        name:'Bedrijven'
    },
    {
        id:6,
        name:'Evenementen'
    },
]