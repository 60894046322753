import styled from "styled-components";


export const HeaderContainer = styled.div`
  flex: 1;
  padding:0px;
  top: 0;
  left: 0;
  right: 0;
  background-color: transparent; /* Add background color if needed */
  z-index: 10000;
  margin-top: 1%;
`;
export const HeaderSubContainer = styled.div`
width: 90%;
margin: auto;
background-color: aqua;
display: flex;
flex-direction: row;
justify-content: space-between;
@media (max-width: 950px) {
    width: 100%;

    
  }

`
export const BackHeaderTitle = styled.div`
display:flex;
align-items: center;
background-color:#EEF3F9 ;
margin-right: 0px;
border-radius: 20px;
padding: 18px;
cursor: pointer;
height: 34px;
`
export const BackHeaderInActiveTitle = styled.div`
display:flex;
align-items: center;
background-color:#ffffff ;
margin-right: 0px;
border-radius: 20px;
padding: 3px 20px 3px 20px;
cursor: pointer;
height: 30px;
`
export const HeaderTitleActive = styled.p`
font-size: 16px;
font-weight: 600;
color: #0A2252;
cursor: pointer;
`
export const HeaderTitle = styled.p`
font-size: 16px;
font-weight: 400;
color: #9FA4AA;
margin-right: 5px;
cursor: pointer;
font-family: 'SweetSansRegular';
`
export const RightTitleHeader = styled.p`
  color: ${({ isActive }) => (isActive ? '#015a3b' : '#2a0800')}; // Active color
  font-size: 14px;
  font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};
  margin-left: 5px;
display: inline;
align-self: center;
font-family: 'SweetSansRegular';

&:hover {
    color: #015a3b; // Hover color (without condition)
    font-weight: bold;
  }

`

export const Userimg = styled.img`
width: 40px;
height: 40px;
border-radius: 50%;
margin-right: 5px;
`
export const UseName = styled.p`
color: #0A2252;
font-size: 16px;
font-weight: 600;
align-self: center;
`
export const Logoimgmain1 = styled.img`
width: 300px;
object-fit: contain;
align-self: center;
cursor: pointer;
margin-top: 0px;
margin-left: -5px;

@media (max-width: 950px) {
  margin-left: 3px;

    
  }
`
export const Logoimgmain = styled.img`
width: 300px;
object-fit: contain;
align-self: center;
cursor: pointer;
margin-top: 5px;
margin-left: -4px;
@media (max-width: 950px) {
  width: 280px;
  margin-top: 10px;

  }

`
export const BackLink = styled.div`
display: inline-block;
position: relative;
font-family: 'SweetSansRegular';
font-size: 14px;
color: #2a0800; /* Dark brown text color */
margin-top: 0px;
cursor: pointer;

`
export const ArrowIc = styled.span`
margin-right: 5px;
color: #f6b732; /* Orange color */
`
export const TextLink = styled.span`
color: #2a0800;
font-size: 14px;
font-weight: 400;
margin-left: 5px;
font-family: 'SweetSansRegular';
&:hover {
    color: #015a3b; // Hover color (without condition)
  }
`
export const Underline = styled.div`
 width: 100%;
  height: 2px;
  background-color: #f6b732; /* Orange color */
  position: absolute;
  bottom:-5px;
  left: 0;
`

// CardLink

export const BackLinkCard = styled.div`
display: inline-block;
position: relative;
font-family: 'SweetSansRegular';
font-size: 12px;
color: #FFFFFF; /* Dark brown text color */
cursor: pointer;
margin-top: 0px !important;

`
export const ArrowIcCard = styled.span`
margin-right: 0px;
color: #FFFFFF; /* Orange color */
`
export const TextLinkCard = styled.span`
color: #FFFFFF;
font-size: 12px;
font-weight: 400;
font-family: 'SweetSansRegular';

`
export const UnderlineCard = styled.div`
 width: 100%;
  height: 1.5px;
  background-color: #FFFFFF; /* Orange color */
  position: absolute;
  bottom:-3px;
  left: 0;
`

export const StarImage = styled.img `
width: 20px;
height: 20px;
`