import React, { useState } from "react";
import {
  Box,
  Container,
  Typography,
  Grid,
  Paper,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Image1 from "../../Assets/Images/contact_image_1.png";
import Image2 from "../../Assets/Images/contact_image_2.png";
import useMediaQuery from "../../Hooks/MediaQuery";
import AddIcon from "@mui/icons-material/Add"; // For the plus icon
import RemoveIcon from "@mui/icons-material/Remove"; // For the minus icon
const ContactPage = () => {
  const [expanded, setExpanded] = useState("panel0");
  const isMobile = useMediaQuery('(min-width: 950px)');


  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <Container
      sx={{
        paddingLeft: { md: "0px" },
        paddingRight: { md: "0px" },
        maxWidth: { xs: "95%", sm: "90%", md: "90%", lg: "90%", xl: "90%" },  // Different widths for different breakpoints
        padding: '0px',    // No padding inside the container
        margin: '0 auto',  // Center the container horizontally
      }}
    >
      {/* Header Section */}
      {/* <Box sx={{ textAlign: "center", my: 4, py: 5 }}>
        <Typography sx={{ fontWeight: "900", color: "#015a3b", fontFamily: 'MontExtraBold', fontSize: '40px' }}>
          Contact
        </Typography>
      </Box> */}
      <Grid item xs={12} sx={{ textAlign: "center", my: 4, py: 5 }}>
        <Typography
          align="center"
          sx={{ fontWeight: "900", color: "#015a3b", mb: 2, fontFamily: 'MontExtraBold', fontSize: '40px' }}
        >
          Contact
        </Typography>
        <Typography
          variant="body2"
          align="center"
          color="textSecondary"
          sx={{ mb: 4, width: isMobile ? '70%' : '90%', textAlign: 'center', alignSelf: 'center', margin: 'auto', color: "#a29390" }}
          style={{ fontWeight: '300', fontFamily: 'SweetSansRegular', marginBottom: isMobile ? '' : '10%' }}

        >
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry. Lorem Ipsum has been the industry's standard dummy text
          ever since the 1500s, when an unknown printer took a galley of type
          and scrambled it to make a type specimen book
        </Typography>
      </Grid>
      {/* Main Content Section */}
      <Grid container>
        {/* Image Section */}
        <Grid
          item
          xs={12}
          md={7}
          order={{ xs: 2, md: 1 }} // Reorder on mobile

        >
          <Box
            component="img"
            src={Image1}
            alt="Contact Us"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
              height: { xs: "100%", md: "410px" }, // Set different heights for mobile and desktop
              borderRadius: {
                xs: "0px 0px 4px 4px", // Apply for mobile screens
                md: "4px 0px 0px 4px", // Apply for medium and larger screens
              },
            }}
          />
        </Grid>

        {/* Contact Info Section */}
        <Grid
          item
          xs={12}
          md={5}
          order={{ xs: 1, md: 2 }} // Reorder on mobile
        >
          <Paper
            style={{
              backgroundColor: "#efefef",
              boxShadow: "none",

            }}
            // Responsive styling for mobile view
            sx={{
              paddingTop: "25px",
              paddingBottom: "20px",
              paddingLeft: "50px",
              height: { xs: "100%", md: "89%" }, // Set different heights for mobile and desktop
              paddingRight: "50px",
              borderRadius: {
                xs: "4px 4px 0px 0px", // Apply for mobile screens
                md: "0px 4px 4px 0px", // Apply for medium and larger screens
              },
            }}
          >
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", color: "#f6b732", fontFamily: 'MontBold' }}
            >
              Neem contact met ons op
            </Typography>

            {/* Phone Number */}
            <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
              <IconButton
                style={{
                  backgroundColor: "#015a3b",
                  color: "white",
                  borderRadius: "50%",
                  p: 1,
                }}
              >
                <PhoneIcon />
              </IconButton>
              <Box sx={{ ml: 3, marginTop: { xs: "30px", md: "25px" } }}>
                <Typography
                  fontSize={"14px"}
                  color={"#015a3b"}
                  fontWeight={"bold"}
                  fontFamily={"SweetSansBold"}
                >
                  0546 624 087
                </Typography>
                <Typography variant="body2" color="#c5bdbb" style={{ fontFamily: 'SweetSansRegular' }}>
                  De klantenservice is bereikbaar op werkdagen van 9:00 uur tot
                  17:00 uur
                </Typography>
              </Box>
            </Box>

            {/* Email Address */}
            <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
              <IconButton
                style={{
                  backgroundColor: "#015a3b",
                  color: "white",
                  borderRadius: "50%",
                  p: 1,
                }}
              >
                <EmailIcon />
              </IconButton>
              <Box sx={{ ml: 3, marginTop: { xs: "30px", md: "25px" } }}>
                <Typography
                  color={"#015a3b"}
                  fontWeight={"bold"}
                  fontFamily={"SweetSansBold"}
                  fontSize={"14px"}

                >
                  info@allesintubbergen.nl
                </Typography>
                <Typography variant="body2" color="#c5bdbb" style={{ fontFamily: 'SweetSansRegular' }}>
                  We doen ons uiterste best om binnen 24 uur te reageren.
                </Typography>
              </Box>
            </Box>

            {/* Address */}
            <Box sx={{ mt: 4 }}>
              <Typography variant="body2" color="#c5bdbb" style={{ fontFamily: 'SweetSansRegular' }}>
                Uelserweg 134
              </Typography>
              <Typography variant="body2" color="#c5bdbb" style={{ fontFamily: 'SweetSansRegular' }}>
                7633 TE Mander
              </Typography>
              <Typography variant="body2" color="#c5bdbb" style={{ fontFamily: 'SweetSansRegular' }}>
                Netherlands
              </Typography>
            </Box>
          </Paper>
        </Grid>
      </Grid>
      {/* Faq Section */}
      <Grid container style={{ marginTop: "50px", marginBottom: "50px" }}>
        {/* Left Column - FAQ Section */}
        <Grid
          item
          xs={12}
          md={9}
          sx={{ p: isMobile ? 7 : 5, margin: 'auto' }}
          // style={{ backgroundColor: "#efefef" }}
          order={{ xs: 2, md: 1 }} // Reorder on mobile
        >
          <Typography
            variant="h6"
            style={{
              fontWeight: "bold",
              color: "#f6b732",
              marginBottom: "30px",
              fontFamily: 'MontBold',
              textAlign: 'center'
            }}
          >
            Veel gestelde vragen
          </Typography>

          {/* Accordion Items */}
          {/* Accordion Items */}
          {["Vraag 1", "Vraag 2", "Vraag 3", "Vraag 4", "Vraag 5"].map(
            (question, index) => (
              <Accordion
                key={index}
                elevation={0}
                expanded={expanded === `panel${index}`}
                onChange={handleChange(`panel${index}`)}
                sx={{
                  marginTop: "5px", marginBottom: '18px',
                  boxShadow: "none",
                  borderTop:'0px',
                  borderTop: '0px solid #efefef', 
                  '&:before': {
                    display: 'none',
                }
                }} // Add border to the Accordion
              >
                <AccordionSummary
                  expandIcon={
                    expanded === `panel${index}` ? (
                      <RemoveIcon style={{ color: "#F7B324" }} />
                    ) : (
                      <AddIcon style={{ color: "#F7B324" }} />
                    )
                  }
                  aria-controls={`panel${index}bh-content`}
                  id={`panel${index}bh-header`}
                  style={{
                    border:'none',
                    backgroundColor: expanded === `panel${index}` ? "#efefef" : "#efefef",
                    color: expanded === `panel${index}` ? "#48705b" : "#48705b",
                  }}
                >
                  <Typography fontWeight={"bold"} fontFamily={"SweetSansBold"}>
                    {question}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails style={{ backgroundColor: "#efefef" }}>
                  <Typography
                    style={{
                      fontWeight: "300",
                      fontFamily: "SweetSansRegular",
                      color: "#898989",
                      fontSize: "14px",
                    }}
                  >
                    Lorem Ipsum is simply dummy text of the printing and typesetting
                    industry. Lorem Ipsum has been the industry's standard dummy text
                    ever since the 1500s.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            )
          )}

        </Grid>
        {/* <Grid
          item
          xs={12}
          md={5}
          order={{ xs: 2, md: 1 }} // Reorder on mobile
        >
          <Box
            component="img"
            src={Image2}
            alt="Cycling group"
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              width: "100%",
              height: { xs: "300px", md: expanded ? "570px" : "530px" }, // Set different heights for mobile and desktop
              objectFit: "cover",
              borderRadius: {
                xs: "0px 0px 4px 4px", // Apply for mobile screens
                md: "0px 4px 4px 0px", // Apply for medium and larger screens
              },
            }}
          />
        </Grid> */}
      </Grid>
    </Container>
  );
};

export default ContactPage;
