import styled from "styled-components";

export const ContainerImageContent = styled.div`
width: 100%;
margin: auto;
margin-top: 5%;
@media (max-width: 950px) {
  width: 100%;
  margin: auto;
  margin-top: 10%;



    
  }
`
export const CardsContainer = styled.div`
margin: auto !important;
width: 100%;
margin-top: 2% !important;
@media (max-width: 950px) {
  margin-top: 10% !important;
  width: 100%;
  }
`
export const CardsSubContainer = styled.div`
width: 100%;
margin: auto !important;
@media (max-width: 950px) {
  width: 100%;
  }

`
export const Heading = styled.p`
color: black;
font-size: 25px;
font-weight: 500;
text-align: left;
margin-bottom: 0px;
font-family: 'MontItalic';
@media (max-width: 950px) {
  text-align: center;
  font-size: 18px;
  margin-top: 10% !important;
  margin: auto;
    
  }
`
export const SubHeading = styled.p`
color: #fff;
font-size: 45px;
font-weight: 700;
text-align: left;
margin-top: 10px;
margin-bottom: 0px;
font-family: 'MontBlack';
@media (max-width: 950px) {
  text-align: center;
  font-size: 28px;
  justify-content: center;
  align-items: center;
  align-self: center;

  }


`
// export const CardsContainerPosi = styled.div`
// display: flex;
// flex-direction: row;
// flex-wrap: wrap;
// margin-top: 3%;

// `
export const CardsContainerPosi = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); // Adjust card width as needed
  gap: 16px;
  padding: 0px;
  align-items: start;
  @media (min-width: 950px) {
    grid-template-columns: repeat(4, 1fr); // Ensures 4 cards per row for larger screens
  }
`;

export const BackImgHome = styled.div`
  border-radius: 50%;
  width: 45px; // Fixed size to ensure a circle
  height: 45px; // Equal to width to ensure a circle
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  align-self: center;
  @media (max-width: 950px) {
    width: 25px; // Fixed size to ensure a circle
    height: 25px; // Equal to width to ensure a circle
      }
`;
export const BackImgHome1 = styled.div`
  border-radius: 50%;
  width: 50px; // Fixed size to ensure a circle
  height: 50px; // Equal to width to ensure a circle
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
`;
export const CardsButtonContainer = styled.div`
width: 100%;
margin: auto;
margin-bottom: 4%;
@media (max-width: 950px) {
  width: 100%; 
  }
`
export const ButtonDirec = styled.div`
display: flex;
flex-direction: row;
flex-wrap: wrap;

@media (max-width: 950px) {
  flex-wrap: wrap;
  width: 100%;
  }

`

export const ButtonSearchDirec = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
@media (max-width: 950px) {
 flex-direction: column;
    
  }
`
export const SearchTitle1 = styled.p`

font-size: 16px;
font-weight: 400;
color: #fff;

`

export const ShowMore = styled.p`
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 18px;
text-align: left;
cursor: pointer;
color: #5076FF;
display: inline;


`;