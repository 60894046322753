// import React from "react";
// import { Card, Skeleton, Box } from "@mui/material";
// import useMediaQuery from "../Hooks/MediaQuery";

// const CardSkeleton = () => {
//   const isMobile = useMediaQuery("(min-width: 950px)");

//   return (
//     <Box
//       sx={{
//         display: "grid",
//         gridTemplateColumns: isMobile ? "repeat(5, 1fr)" : "repeat(2, 1fr)", // 4 cards on larger screens, 2 on smaller
//         gap: "16px", // Space between cards
//         padding: "0px", // Optional padding around the grid
//       }}
//     >
//       {Array.from({ length: 15 }).map((_, index) => (
//         <Card
//           key={index}
//           sx={{
//             marginBottom: "2%",
//             borderRadius: "5px",
//             cursor: "pointer",
//             overflow: "hidden",
//             boxShadow: "none", // Hide elevation by setting boxShadow to 'none'
//             width: "100%", // Card width fills the grid column
//           }}
//         >
//           <Skeleton
//             variant="rectangular"
//             width="100%" // Skeleton also fills the grid column
//             height={400}
//             style={{ boxShadow: "none" }}
//           />
//         </Card>
//       ))}
//     </Box>
//   );
// };

// export default CardSkeleton;
import React from "react";
import { Card, Skeleton, Box } from "@mui/material";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import useMediaQuery from "../Hooks/MediaQuery";

const CardSkeleton = () => {
  return (
    <Box sx={{ padding: "0px" }}>
      <ResponsiveMasonry gutter="15px" margin="10px"
        columnsCountBreakPoints={{
          200: 2,    // Extra small screens, like small mobile devices
          500: 2,    // Small mobile screens
          750: 3,    // Medium devices, like tablets
          900: 3,    // Larger tablets and smaller desktops
          1200: 4,   // Regular desktops and larger
          1600: 5    // Extra large monitors, widescreens
        }}
      >
        <Masonry gutter="20px">
          {Array.from({ length: 15 }).map((_, index) => (
            <Card
              key={index}
              sx={{
                borderRadius: "5px",
                cursor: "pointer",
                overflow: "hidden",
                boxShadow: "none", // Hide elevation by setting boxShadow to 'none'
              }}
            >
              <Skeleton
                variant="rectangular"
                width="100%"
                height={index % 2 === 0 ? 400 : 350} // Example of varying heights based on index
                style={{ boxShadow: "none" }}
              />
            </Card>
          ))}
        </Masonry>
      </ResponsiveMasonry>
    </Box>
  );
};

export default CardSkeleton;
