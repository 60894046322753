import React, { useContext, useEffect, useState } from "react";
import { Button } from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import Cards from "./Cards";
import Searchbar from "./Searchbar";
import { GetList } from "../Api/Api";
import { ButtonTextData } from "../Data/Data";
import { ButtonDirec, ButtonSearchDirec, CardsButtonContainer, CardsSubContainer } from "../Pages/HomeMain/HomeStyles";
import { CircularProgress } from '@mui/material';
import useMediaQuery from "../Hooks/MediaQuery";
import { Context } from "../Context/ContextStates";
import MasonryLayout from "./Masonry";

const CategoriesCompo = () => {
    const isMobile = useMediaQuery('(min-width: 950px)');
    const [step, setStep] = useState(0);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false); // Add loading state
    const { search, setSearch, category } = useContext(Context);

    useEffect(() => {
        setIsLoading(true); // Start loading
        GetList().then((response) => {
            setData(response.result);
            setIsLoading(false); // End loading once data is fetched
        }).catch(error => {
            console.error("Failed to fetch data:", error);
            setIsLoading(false); // Ensure loading is false in case of an error too
        });
    }, []);

    const getCategoryFromStep = (step) => {
        // Finds the category name based on the current step
        return step === 0 ? "Alles" : category[step - 1]?.name || "";
    };


    // const filteredData = data.filter(item => {
    //     const category = getCategoryFromStep(step);
    //     const latestPost = item.latestPost && item.latestPost[0]; // Get the first latest post

    //     // Ensure that latestPost and latestPost.user exist
    //     const matchesSearch = search && latestPost && latestPost.user && (
    //         latestPost.user.name.toLowerCase().includes(search.toLowerCase())
    //         // latestPost.text.toLowerCase().includes(search.toLowerCase())
    //     );

    //     return (category === "Alles" || item.categories.includes(category)) && (!search || matchesSearch);
    // });

    const filteredData = data
    .filter(item => item.latestPost && item.latestPost.length > 0 &&
        item.latestPost[0] &&
        !item.latestPost[0]?.error && // Exclude if there's an error
        item.latestPost[0]?.openGraph?.url !== "https://www.facebook.com/" // Exclude invalid URLs
    )
    .filter(item => {
        const category = getCategoryFromStep(step);
        const latestPost = item.latestPost && item.latestPost[0]; // Get the first latest post

        // Ensure that latestPost and latestPost.user exist
        const matchesSearch = search && latestPost && latestPost.user && (
            latestPost.user.name.toLowerCase().includes(search.toLowerCase())
            // latestPost.text.toLowerCase().includes(search.toLowerCase())
        );

        return (category === "Alles" || item.categories.includes(category)) && (!search || matchesSearch);
    });



    return (
        <>
            <CardsButtonContainer>
                <ButtonSearchDirec>
                    <ButtonDirec>
                        {/* Add the "Alles" button */}
                        <Button
                            key="alles"
                            variant={step === 0 ? "contained" : "outlined"}
                            onClick={() => setStep(0)}
                            startIcon={step === 0 ? <CheckIcon /> : null}
                            sx={{
                                marginRight: '10px',
                                color: step === 0 ? '#0C5030' : 'white',
                                borderColor: '#fff',
                                border: '2px solid #fff',
                                padding: '6px 10px',
                                fontSize: isMobile ? '0.775rem' : '0.80rem',
                                minWidth: isMobile ? '90px' : '100px',
                                height: '33px',
                                textDecoration: 'none',
                                textTransform: 'none',
                                fontFamily: 'SweetSansRegular',
                                fontWeight: '400',
                                marginBottom: isMobile ? '1%' : '2%',
                                backgroundColor: step === 0 ? '#fff' : 'transparent',
                                boxShadow:'none',
                                '&:hover': {
                                    borderColor: '#fff',
                                    backgroundColor: '#fff',
                                    color: '#0C5030',
                                    boxShadow:'none'

                                },
                                '&:active': {
                                    backgroundColor: '#fff',
                                    color: '#0C5030',
                                    boxShadow:'none'

                                },
                            }}
                        >
                            Alles
                        </Button>

                        {/* Map over category buttons */}
                        {category.slice(0, 6).map((buttonData, index) => (
                            <Button
                                key={buttonData.id}
                                variant={step === index + 1 ? "contained" : "outlined"}
                                onClick={() => setStep(index + 1)}
                                startIcon={step === index + 1 ? <CheckIcon /> : null}
                                sx={{
                                    marginRight: '10px',
                                    color: step === index + 1 ? '#0C5030' : 'white' ,
                                    borderColor: '#fff',
                                    border: '2px solid #fff', // Consistent border width
                                    padding: '8px 12px', // Consistent padding
                                    fontSize: isMobile ? '0.775rem' : '0.80rem',
                                    height: '33px',
                                    textDecoration: 'none',
                                    textTransform: 'none',
                                    fontFamily: 'SweetSansRegular',
                                    fontWeight: '400',
                                    marginBottom: isMobile ? '1%' : '2%',
                                    backgroundColor: step === index + 1 ? '#fff' : 'transparent', // Background on active
                                    boxShadow:'none',
                                    boxSizing: 'border-box', // Ensures padding and borders don't affect size
                                    transition: 'background-color 0.3s, color 0.3s', // Smooth color transitions only
                                    '&:hover': {
                                        backgroundColor: '#fff', // Keep hover consistent
                                        color: '#0C5030',
                                        border: '2px solid #fff', // Consistent border width
                                        boxShadow:'none'


                                    },
                                    '&:active': {
                                        backgroundColor: '#fff',
                                        color: '#0C5030',
                                        boxShadow:'none'

                                    },
                                   
                                }}
                            >
                                {buttonData.name}
                            </Button>
                        ))}


                    </ButtonDirec>
                    <div style={{ marginTop: isMobile ? '0%' : '2%', marginLeft: isMobile ? '2%' : '', marginBottom: isMobile ? '' : '5%' }}>
                        <Searchbar />
                    </div>
                </ButtonSearchDirec>
            </CardsButtonContainer>
            <CardsSubContainer>
                <Cards data={filteredData} isLoading={isLoading} />
            </CardsSubContainer>
        </>
    );
};

export default CategoriesCompo;
