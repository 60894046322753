// import React, { useContext, useState } from "react";
// import { FormControl, OutlinedInput, InputAdornment, Box, InputLabel, Button } from "@mui/material";
// import SearchIcon from '@mui/icons-material/Search';
// import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
// import useMediaQuery from "../Hooks/MediaQuery";
// import { Context } from "../Context/ContextStates";
// import ClearIcon from '@mui/icons-material/Clear';
// const Searchbar = () => {
//     const isMobile = useMediaQuery('(min-width: 950px)');
//     const { search, setSearch } = useContext(Context)
//     const [localSearchTerm, setLocalSearchTerm] = useState("");
//     const handleSearchClick = () => {
//         setSearch(localSearchTerm);  // Update parent component's search term
//     };
//     const handleClearSearch = () => {
//         setLocalSearchTerm("");  // Clear the input field
//         setSearch("");           // Optionally clear the search term in context
//     };

//     return (
//         <div style={{ display: 'flex', flexDirection: isMobile ? 'row' : 'column' }}>
//             <Box sx={{ position: 'relative', display: 'inline-flex', alignSelf: 'center', ml: isMobile ? '1.5%' : '', mt: isMobile ? '-1.5%' : '', mr: isMobile ? '2%' : '' }}>
//                 <Box sx={{ display: 'flex', flexDirection: 'column' }}>
//                     <InputLabel htmlFor="search-input" sx={{ color: '#fff', fontSize: '16px', fontWeight: '400', marginBottom: '2%', fontFamily: 'SweetSansRegular', }}>Wat is je zoekgebied?</InputLabel>
//                     <>
//                         <FormControl variant="outlined" fullWidth>
//                             <OutlinedInput
//                                 fullWidth
//                                 value={localSearchTerm}
//                                 onChange={e => setLocalSearchTerm(e.target.value)}
//                                 sx={{
//                                     backgroundColor: '#fff',
//                                     borderRadius: '30px',
//                                     border: '2px solid #df4c38',
//                                     color: '#918450',
//                                     pr: '50px', // padding-right to ensure text doesn't overlap with the icon
//                                     "&.MuiOutlinedInput-root": {
//                                         "& fieldset": {
//                                             borderColor: 'transparent',
//                                         },
//                                         "&:hover fieldset": {
//                                             borderColor: "transparent",
//                                         },
//                                         "&.Mui-focused fieldset": {
//                                             border: 'none',
//                                             boxShadow: 'none',
//                                         },
//                                     },
//                                 }}
//                                 size="small"
//                                 placeholder="bijv. Amsterdam"
//                                 startAdornment={
//                                     <InputAdornment position="start">
//                                         <SearchIcon style={{ color: '#000' }} />
//                                     </InputAdornment>
//                                 }
//                             />
//                         </FormControl>
//                         <Box 
//                         onClick={() => localSearchTerm === "" ? '' : handleClearSearch()}
//                         sx={{
//                             position: 'absolute',
//                             top: '70%',
//                             right: '0.5px', // Adjust based on desired padding inside the input
//                             transform: 'translateY(-50%)',
//                             backgroundColor: '#df4c38',
//                             borderRadius: '50%',
//                             display: 'flex',
//                             alignItems: 'center',
//                             justifyContent: 'center',
//                             width: 40, // Adjust as needed
//                             height: 40, // Keep the aspect ratio circular
//                             cursor: 'pointer',

//                         }}>
//                             {localSearchTerm === "" ?
//                                 <LocationSearchingIcon style={{ color: '#fff' }} />
//                                 :
//                                 <ClearIcon style={{ color: '#fff' }} />
//                             }
//                         </Box>
//                     </>

//                 </Box>
//             </Box>
//             <Button
//                 variant="standard"
//                 onClick={handleSearchClick}
//                 sx={{
//                     alignSelf: 'center',
//                     backgroundColor: '#2a0800',
//                     color: '#fff',
//                     padding: '6px 10px',
//                     fontSize: '0.9rem',
//                     minWidth: '100px',
//                     height: '40px',
//                     borderRadius: '30px',
//                     textDecoration: 'none',
//                     textTransform: 'none',
//                     marginTop: '5%',
//                     fontFamily: 'SweetSansRegular',
//                     '&:hover': {
//                         borderColor: '#2a0800',
//                         backgroundColor: '#fff',
//                         color: '#2a0800',
//                     },
//                 }}
//             >
//                 Zoeken
//             </Button>
//         </div>
//     );
// };

// export default Searchbar;


import React, { useContext, useState } from "react";
import { FormControl, OutlinedInput, InputAdornment, Box, InputLabel, Button } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import LocationSearchingIcon from '@mui/icons-material/LocationSearching';
import useMediaQuery from "../Hooks/MediaQuery";
import { Context } from "../Context/ContextStates";
import ClearIcon from '@mui/icons-material/Clear';
const Searchbar = () => {
    const isMobile = useMediaQuery('(min-width: 950px)');
    const { search, setSearch } = useContext(Context)
    const [localSearchTerm, setLocalSearchTerm] = useState("");
    const handleInputChange = (e) => {
        const value = e.target.value;
        setLocalSearchTerm(value);
        setSearch(value); // Update search term in context
    };
    const handleClearSearch = () => {
        setLocalSearchTerm("");  // Clear the input field
        setSearch("");           // Optionally clear the search term in context
    };

    return (
        <FormControl variant="outlined"> {/* Adjust width as needed */}
            <OutlinedInput

                value={localSearchTerm}
                onChange={handleInputChange}
                sx={{
                    backgroundColor: '#fff',
                    borderRadius: '30px',
                    border: '2.5px solid #f7b937',
                    color: '#393E41',
                    height: '40px', // Adjust height
                    fontSize:'15px',
                    width: isMobile ? '300px' : '330px',
                    fontFamily:"SweetSansProThinItalic",
                    "&.MuiOutlinedInput-root": {
                        "& fieldset": {
                            borderColor: 'transparent',
                        },
                        "&:hover fieldset": {
                            borderColor: "transparent",
                        },
                        "&.Mui-focused fieldset": {
                            border: 'none',
                            boxShadow: 'none',
                        },
                        "& input::placeholder": {
                            color: '#393E41', // Set placeholder color if needed
                            fontSize: '15px',  // Adjust size as needed
                            letterSpacing:'-0.75px',
                            fontFamily: "SweetSansProThinItalic", // Same font as text
                          },
                    },
                }}
                size="small"
                placeholder="zoek op trefwoord(en)"
                endAdornment={
                    <InputAdornment position="end">
                        {localSearchTerm === "" ?
                            <SearchIcon style={{ color: '#f7b937' }} />
                            :
                            <ClearIcon style={{ color: '#f7b937',cursor:'pointer' }} onClick={handleClearSearch}/>
                        }
                    </InputAdornment>
                }
            />
        </FormControl>
    );
};

export default Searchbar;
