import React from "react";
import { LightTextAuth, LinkTextAuth } from "../Pages/AuthFlow/AuthStyles";
import { Link } from "@mui/material";




const LinkText = ({ title, title1, to, onClick, style, margin }) => {
    return (
        <div style={style}>
            <LinkTextAuth>{title}</LinkTextAuth>
            <Link to={to} onClick={onClick} 
            style={{ color: '#F7B324', 
            fontSize: 13, 
            cursor: 'pointer', 
            marginLeft: '0.5%',
             fontWeight: '600',
              fontFamily: 'SF Pro Display',
              textDecoration: 'none',
               }} color="inherit">{title1}</Link>
        </div>
    )
}
export default LinkText
